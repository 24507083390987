import {FilterEntityAttribute} from "../base";
import {BaseOptionsSource, FilterSchemaBaseEntityAttribute} from "../EntityAttributeFilterEditor";
import {LabelWithCount} from "../../../../Misc";

export function FilterEntityTagNumber(options, operation) {
    return FilterEntityAttribute(options, 'entity_tag_number', 'Tag Number', operation);
}


export function FilterSchemaEntityTagNumber() {
    return FilterSchemaBaseEntityAttribute(
        {value: 'tag_number', label: 'Tag Number'},
        BaseOptionsSource('/search-options/tag-numbers', x => ({
            value: x.value,
            tagLabel: x.value,
            label: <LabelWithCount {...x}/>
        })),
        FilterEntityTagNumber
    );
}
