import {SelectIdContextModal} from "./SelectIdContextModal";
import {useCallback} from "react";
import {AdaptedSelectIdDataSource} from "../../tables";
import {PmPlansDataSource} from "../../../pages/pm-plans/PmPlans";


export function AssignPmPlanModal() {
    const dataSourceCtor = useCallback((q) => {
        return new AdaptedSelectIdDataSource(
            new PmPlansDataSource(q)
        );
    }, []);

    return (
        <SelectIdContextModal
            dataSourceCtor={dataSourceCtor}
            title={subject => `Assign PM Plan to: ${subject.title}`}
            expectedType={'assign_pm_plan'}
        />
    );
}
