import {useEffect, useRef, useState} from "react";
import {EhApi} from "../../../services";
import {ExpandedNodeChild, Hierarchy} from "../../../components/hierarchy";
import {useHistory, useLocation} from "react-router-dom";
import {
	FallbackRootDataSource,
	MeasuredRootDataSource,
	RootDataSource,
	updateTreeData
} from "../../../components/hierarchy/Hierarchy";


export function FlocTree({setSelectedNode}) {
	const [rootDataSource, setRootDataSource] = useState(new RootDataSource());
	const location = useLocation();
	const hierarchyRef = useRef();
	const history = useHistory();

	useEffect(() => {
		const scrollToNode = new URLSearchParams(location.search).get('node');
		let source;
		if (scrollToNode) {
			source = new FallbackRootDataSource(
				new MeasuredRootDataSource(
					new OneRequestSliceHierarchySource(setSelectedNode, scrollToNode),
					'Slice'
				),
				_ => history.push('/hierarchy')
			)
		} else {
			source = new AssetHierarchyRootSource(setSelectedNode);
		}
		setRootDataSource(source);
	}, [location]);

	return (
		<Hierarchy
			rootDataSource={rootDataSource}
			setSelectedNode={setSelectedNode}
			ref={hierarchyRef}
			onRootLoaded={_ => {
				if (hierarchyRef.current) hierarchyRef.current.scrollTo(new URLSearchParams(location.search).get('node'));
			}}
		/>
	);
}


class AssetHierarchyRootSource extends RootDataSource {
	constructor(setSelectedNode) {
		super();
		this._setSelectedNode = setSelectedNode;
	}

	async value() {
		return EhApi.get(
			'/hierarchies/current/preview/root'
		).then(response => ({
			data: [ExpandedNodeChild(response.data, '', this._setSelectedNode)],
			expandedKeys: []
		}));
	}
}


class TreeSliceHierarchySource extends RootDataSource {
	constructor(setSelectedNode, targetNodeId) {
		super();
		this._setSelectedNode = setSelectedNode;
		this._targetNodeId = targetNodeId;
	}

	async value() {
		const loadMultipleNodes = (nodesList) => {
			const requests = nodesList.map(node => EhApi.get(
				`/hierarchies/current/preview/nodes/${node.id}/expanded`
			))
			return Promise.all(requests)
		}

		const start = Date.now();
		return EhApi.get(
			'/hierarchies/current/preview/root'
		).then(root => {
			return EhApi.get(
				`/hierarchies/current/preview/nodes/${this._targetNodeId}/path`
			).then(response => {
				console.log('request finished in', Date.now() - start);
				const nodePath = response.data;
				return loadMultipleNodes(nodePath).then(response => {
					let newTreeData = [ExpandedNodeChild(root.data, '', this._setSelectedNode)];
					const nodesKeys = [];
					let currentKey = '';
					for (let i = 0; i < nodePath.length; ++i) {
						currentKey += '/' + nodePath[i].id;
						nodesKeys.push(currentKey);
						newTreeData = updateTreeData(newTreeData, currentKey, response[i].data, this._setSelectedNode);
					}
					return {
						data: newTreeData,
						expandedKeys: nodesKeys
					}
				})
			})
		})
	}
}


class OneRequestSliceHierarchySource extends RootDataSource {
	constructor(setSelectedNode, targetNodeId) {
		super();
		this._setSelectedNode = setSelectedNode;
		this._targetNodeId = targetNodeId;
	}

	async value() {
		const start = Date.now();
		return EhApi.get(
			`/hierarchies/current/preview/nodes/${this._targetNodeId}/expanded-path`
		).then(response => {
			if (response.data.length === 0) {
				throw new Error('Empty root data');
			}
			console.log('request finished in', Date.now() - start);
			const data = response.data;
			let newTreeData = [ExpandedNodeChild(data[0], '', this._setSelectedNode)];
			const nodesKeys = [];
			let currentKey = '';

			for (const node of data) {
				currentKey += '/' + node.id;
				nodesKeys.push(currentKey);
				newTreeData = updateTreeData(newTreeData, currentKey, node, this._setSelectedNode);
			}

			return {
				data: newTreeData,
				expandedKeys: nodesKeys
			}
		})
	}
}
