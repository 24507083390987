import {EhApi} from "./index";
import {message} from "antd";

export class GeneratedName {
    constructor(entityType) {
        this._entityType = entityType;
    }

    async value() {
        return new Promise((resolve) => {
            let name = "";
            EhApi.get(`/utils/generated_name/${this._entityType}`)
                .then(response => {
                    name = response.data;
                })
                .catch(_ => {message.error("Name is not able to be generated")})
                .finally(_ => {
                    resolve(name)
                })
        })
    }
}