import {NODE_COLORS} from "../constants";

class EntityTypes {
    constructor() {
        this._data = [
            {color: NODE_COLORS.MATERIAL, pretty: "Material", raw: 'material'},
            {color: NODE_COLORS.EQUIPMENT, pretty: "Equipment", raw: 'equipment'},
            {color: NODE_COLORS.PM_PLAN, pretty: "Pm Plan", raw: 'pm_plan'},
            {color: NODE_COLORS.BOM, pretty: "BOM", raw: 'bom'},
            {color: NODE_COLORS.FLOC, pretty: "FLOC", raw: 'floc'},
            {color: NODE_COLORS.CONSTRUCTION_TYPE, pretty: "Construction Type", raw: 'construction_type'},
        ];
    }

    asOptions() {
        return this._data.map(x => ({label: x.pretty, value: x.raw}));
    }

    prettyNameByType(type) {
        return this._data.find(x => x.raw === type).pretty;
    }

    nodeColorByType(type) {
        return this._data.find(x => x.raw === type).color;
    }
}


export default new EntityTypes();
