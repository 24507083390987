import {AsyncGenericEntityForm} from "../../forms";
import {FormContextModal} from "./FormContextModal";
import {
	ComposedExtractedAttribute,
	DefaultExtractedAttribute,
	ExtractedTagNumber
} from "../selected-node/api/ExtractedAttribute";
import {message, Spin} from "antd";
import {AssignClassOnCreation, ExtractedEntityClass} from "../selected-node/api/ExtractedEntityClass";
import {ExtractedConstructionType} from "../selected-node/api/ExtractedConstructionType";
import {GeneratedName} from "../../../services/GeneratedName";
import {useState} from "react";


const extractedAttributes = new ComposedExtractedAttribute([
	new DefaultExtractedAttribute(),
	new ExtractedConstructionType(),
	new ExtractedTagNumber(),
	new ExtractedEntityClass(
		new AssignClassOnCreation('equipment')
	)
]);


const emptyEquipmentAttributes = async () => {
	const name = await new GeneratedName("equipment").value();
	const data = {
		entity_id: name,
		description: '',
		construction_type_name: null,
		tag_number: '',
		entity_class: {
			id: null,
			name: null,
			attributes: []
		}
	};
	return extractedAttributes.extract(data);
};


export function CreateEquipmentModal() {
	const [loading, setLoading] = useState(false);

	return (
		<FormContextModal
			title={subject => `Create new Equipment under: ${subject.title}`}
			expectedType={'create_equipment'}
			formId={"create-equipment-modal"}
			okText={"Create"}
			modalRender={node => (
				<Spin spinning={loading}>
					{node}
				</Spin>
			)}
		>
			{(subject, close, formId) => (
				<AsyncGenericEntityForm
					id={formId}
					attributesSource={emptyEquipmentAttributes}
					setLoading={setLoading}
					onFinish={
						values => subject.handle(values).then(
							_ => close()
						).catch(() => message.error('Failed to create new Equipment'))
					}
				/>
			)}
		</FormContextModal>
	);
}
