import {Redirect, Route, Switch} from "react-router-dom";
import {Boms, Home, Materials, Settings, ConstructionTypes, EntityLinkDispatch, PmPlans} from "./pages";
import {CommonLayout} from "./layouts";
import {SearchResults} from "./pages/search/Search";

function App() {
	return (
		<Switch>
			<Route path={"/"} exact render={_ => <Redirect to={'/hierarchy'}/>}/>

			<Route path={"/entity_links"} exact>
				<EntityLinkDispatch/>
			</Route>

			<Route path={"/hierarchy"} exact>
				<CommonLayout>
					<Home/>
				</CommonLayout>
			</Route>

			<Route path={"/materials"} exact>
				<CommonLayout>
					<Materials/>
				</CommonLayout>
			</Route>

			<Route path={"/boms"} exact>
				<CommonLayout>
					<Boms/>
				</CommonLayout>
			</Route>

			<Route path={"/settings"} exact>
				<CommonLayout>
					<Settings/>
				</CommonLayout>
			</Route>

			<Route path={"/search"} exact>
				<CommonLayout>
					<SearchResults />
				</CommonLayout>
			</Route>

			<Route path={"/construction_types"}>
				<CommonLayout>
					<ConstructionTypes/>
				</CommonLayout>
			</Route>

			<Route path={"/pm_plans"}>
				<CommonLayout>
					<PmPlans/>
				</CommonLayout>
			</Route>
		</Switch>
	);
}

export default App;
