import {message, Upload, Button} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {API_URL} from "../../constants";
import {Auth} from "@digatex/digatex-ui-lib"

export function ImportHierarchyTab() {
  const uploadProps = {
    name: 'file',
    accept: '.xml',
    action: API_URL + `/hierarchies/import`,
    headers: Auth.headers(),
    data: {},
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`Hierarchy imported successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Error importing hierarchy`);
      }
    },
  };
  return (
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Upload hierarchy load file</Button>
      </Upload>
  )
}