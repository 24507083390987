import {useMemo, useState} from "react";
import {Form, Input, Radio, Space} from "antd";
import {BaseOptionsSource, filterOperationsMap} from "../entity-attribute/EntityAttributeFilterEditor";
import {FilterClassAttribute} from "./filters/entity-class-attribute";
import {LabelWithCount} from "../../../Misc";
import {AsyncSelect} from "../../../selects";

export function ClassAttributeFilterEditor({onEditedFilterChanged}) {
    const [attrName, setAttrName] = useState(null);
    const [attrValue, setAttrValue] = useState([]);
    const [filterOperation, setFilterOperation] = useState(filterOperationsMap.equal);

    const selectName = selectedName => {
        setAttrName(selectedName);
        setAttrValue([]);
        onEditedFilterChanged(EditedFilter(selectedName, attrValue, filterOperation));
    }

    const selectValue = selectedValue => {
        setAttrValue(selectedValue);
        onEditedFilterChanged(EditedFilter(attrName, selectedValue, filterOperation));
    }

    const valueOptionsSource = useMemo(() => {
        return BaseOptionsSource(
            `/search-options/class-attribute-values/${attrName}`,
            x => ({value: x.value, label: <LabelWithCount {...x}/>})
        );
    }, [attrName]);

    const nameOptionsSource = useMemo(() => {
        return BaseOptionsSource(
            '/search-options/class-attribute-names',
            x => ({value: x.value, label: <LabelWithCount {...x}/>})
        );
    }, []);

    const valueEditors = {
        [filterOperationsMap.equal]: (
            <AsyncSelect
                onChange={selectValue}
                mode={"multiple"}
                defaultValue={[]}
                value={attrValue}
                optionsSource={valueOptionsSource}
            />
        ),
        [filterOperationsMap.contains]: (
            <Input
                size="small"
                value={attrValue[0]}
                onChange={e => {
                    const targetValue = [e.target.value];
                    selectValue(targetValue);
                }}
            />
        )
    }

    return (
        <>
            <Form.Item label="Name">
                <AsyncSelect
                    placeholder={""}
                    onChange={selectName}
                    optionsSource={nameOptionsSource}
                />
            </Form.Item>
            <Form.Item label="Value">
                {valueEditors[filterOperation]}
            </Form.Item>
            <Radio.Group
                value={filterOperation}
                onChange={e => {
                    setFilterOperation(e.target.value);
                    setAttrValue([]);
                }}
                defaultValue={filterOperationsMap.equal}
            >
                <Space direction="vertical">
                    <Radio
                        value={filterOperationsMap.equal}
                    >
                        Full match
                    </Radio>
                    <Radio
                        value={filterOperationsMap.contains}
                    >
                        Partial match
                    </Radio>
                </Space>
            </Radio.Group>
        </>
    );
}


function EditedFilter(name, values, operation) {
    return {
        canBeAdded: () => {
            return name != null;
        },
        toConcreteFilter: () => FilterClassAttribute(name, values, operation)
    }
}
