import {Layout} from "antd";
import {Header, Sider} from "../../components/layout";


export function CommonLayout({children}) {
	return (
		<Layout style={{height: "100vh"}}>
			<Header/>
			<Layout>
				<Layout>
					<Sider/>
					<Layout.Content
						style={{padding: 12, margin: 12,}}
						// className="site-layout-background"
					>
						{children}
					</Layout.Content>
				</Layout>
			</Layout>
		</Layout>
	);
}
