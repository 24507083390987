import React from 'react';
import ReactDOM from 'react-dom/client';
import {Router} from "react-router-dom";
import './index.less';
import App from './App';
import axios from "axios";
import {createBrowserHistory} from "history";
import {Auth, UIConfigContext} from "@digatex/digatex-ui-lib"
import {message} from "antd";
import {HierarchyAppearanceProvider} from "./contexts";
import {UI_CONFIG} from "./constants"
import {revisionHeaders} from "./components/revision";

const history = createBrowserHistory();

axios.interceptors.request.use((config) => {
	if (Auth.isAuthenticated()) {
		const cb = () => {
			config.headers = {...config.headers, ...Auth.headers(), ...revisionHeaders()}
			return Promise.resolve(config);
		};
		return Auth.updateToken(cb);
	}
});

axios.interceptors.response.use(
	response => response,
	error => {
		const status = error?.response?.status;
		if (status && (status >= 400 && status <= 599)) {
			const data = error.response.data;
			if (data.message != null) {
				message.error(data.message);
			}
			return Promise.reject(error);
		}

		return Promise.reject(error);
	}
);


const root = ReactDOM.createRoot(document.getElementById('root'));

Auth.init(() =>
	root.render(
		<UIConfigContext.Provider value={UI_CONFIG}>
			<HierarchyAppearanceProvider>
				<Router history={history}>
					<App />
				</Router>
			</HierarchyAppearanceProvider>
		</UIConfigContext.Provider>
	));
