import {FilterEntityAttribute} from "../base";
import {BaseOptionsSource, FilterSchemaBaseEntityAttribute} from "../EntityAttributeFilterEditor";
import {LabelWithCount} from "../../../../Misc";

export function FilterEntityClass(options, operation) {
    return FilterEntityAttribute(options, 'entity_class', 'Class', operation);
}


export function FilterSchemaEntityClass() {
    return FilterSchemaBaseEntityAttribute(
        {value: 'class', label: 'Class'},
        BaseOptionsSource('/search-options/classes', x => ({
            value: x.value,
            tagLabel: x.value,
            label: <LabelWithCount {...x} value={`${x.value} (${x.type})`}/>
        })),
        FilterEntityClass
    );
}
