import { loadUIConfig } from "@digatex/digatex-ui-lib"

export const UI_CONFIG = loadUIConfig()

export const API_URL = process.env.REACT_APP_API_URL

export const REACT_APP_IH_LINK = process.env.REACT_APP_IH_LINK ?? 'https://inventory.demo.digatex.com'
export const REACT_APP_DM_LINK = process.env.REACT_APP_DM_LINK ?? 'https://dm.demo.digatex.com'
export const REACT_APP_KH_LINK = process.env.REACT_APP_KH_LINK ?? 'https://analytics.1055-300.digatex.com'
export const REACT_APP_WB_LINK = process.env.REACT_APP_WB_LINK ?? 'https://workbench.sims-test.digatex.com'

export const NODE_COLORS = {
    PM_PLAN: "#d2b1a3",
    MATERIAL: "#f9856e",
    FLOC: "#c2d4d8",
    EQUIPMENT: "#bff8ae",
    BOM: "#f5ddbc",
    CONSTRUCTION_TYPE: "#f39522",
}