import {NodePath} from "../response-to-tree-data/response-adapters";
import {AsyncDataSource} from "../../tables";
import {EntityLinksSource} from "../../dropdowns";

export class SelectedNode {
	id() {
		return null;
	}

	logs() {
		return {
			hidden: true,
			objectId: null
		}
	}

	async namedPath() {
		return {
			value: new NodePath(),
			open: path => {}
		};
	}

	pmPlans() {
		return {
			hidden: true,
			dataSource: new AsyncDataSource()
		}
	}

	async links() {
		return new EntityLinksSource();
	}

	async parents() {
		return [];
	}

	async attributes() {
		return [];
	}

	async edit(values) {
		return values;
	}
}

export class NullSelectedNode extends SelectedNode {
}


export class SelectedNodeWrap extends SelectedNode {
	constructor(origin) {
		super();
		this._origin = origin;
	}

	id() {
		return this._origin.id();
	}

	logs() {
		return this._origin.logs();
	}

	async namedPath() {
		return this._origin.namedPath();
	}

	async parents() {
		return this._origin.parents();
	}

	async attributes() {
		return this._origin.attributes();
	}

	async edit(values) {
		return this._origin.edit(values);
	}

	pmPlans() {
		return this._origin.pmPlans();
	}

	async links() {
		return this._origin.links();
	}
}