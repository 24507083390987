import React, {useContext, useEffect, useState} from "react";
import {HierarchyGroupOperationsContext} from "../HierarchyGroupOperationsContext";
import {HierarchyActionsContext, withCheckedKey} from "../HierarchyActionsContext";
import {empty} from "../../../utils";
import {message} from "antd";
import {ConstructionTypeSelect, ConstructionTypeSelectContext, EntityClassSelect} from "../../forms/data-items";
import {EhApi} from "../../../services";
import {GenericEntityFormModal} from "../../modals";
import {ComposedExtractedAttribute, SimpleExtractedAttribute} from "../selected-node/api/ExtractedAttribute";
import {hierarchyNodes} from "../Hierarchy";
import {FilterableFormItem, RuleSingleValue} from "../../forms";


const extractedAttributes = new ComposedExtractedAttribute([
    new SimpleExtractedAttribute(
        model => [{
            path: 'class',
            value: model.class,
            toRequestValues: values => ({class_id: values.class.id}),
            toFormItem: () => (
                <FilterableFormItem
                    required
                    name={["class"]}
                    label={"Entity Class Target"}
                    key={"class"}
                    rules={[
                        RuleSingleValue(
                            value => value.id != null,
                            'Entity Class is required'
                        )
                    ]}
                >
                    <AdaptedEntityClassSelect/>
                </FilterableFormItem>
            )
        }]
    ),
    new SimpleExtractedAttribute(
        model => [{
            path: 'construction_type',
            value: model.construction_type,
            toFormItem: () => (
                <FilterableFormItem
                    required
                    name={["construction_type"]}
                    label={"Construction Type To Assign"}
                    key={"construction_type"}
                    rules={[
                        RuleSingleValue(
                            value => value.id != null,
                            'Construction Type is required'
                        )
                    ]}
                >
                    <AdaptedConstructionTypeSelect/>
                </FilterableFormItem>
            )
        }]
    )
]);


const emptyAssignAttributes = extractedAttributes.extract({
    construction_type: {
        id: null,
        name: null
    },
    class: {
        id: null,
        name: null
    }
});


export function GroupConstructionTypeAssignModal() {
    const setGroupOperations = useContext(HierarchyGroupOperationsContext);
    const {treeData, setTreeData} = useContext(HierarchyActionsContext);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handle = setGroupOperations ?? empty;
        const key = 'assign-ct-type';

        handle(prev => {
            return [
                ...prev,
                {
                    order: 1,
                    toItem: () => ({
                        data: {
                            key: key,
                            label: 'Group Assign Construction Type',
                        },
                        onItemClick: withCheckedKey(key, _ => {
                            setVisible(true)
                        })
                    })
                }
            ];
        })
        return () => {
            setGroupOperations(prev => prev.filter(el => el.toItem().data.key !== key));
        }
    }, [setGroupOperations]);

    const handleFinish = values => {
        return new Promise((resolve, reject) => {
            EhApi.put(
                '/hierarchies/current/preview/nodes/construction-type-targets',
                {
                    construction_type_id: values.construction_type.id,
                    entity_class_id: values.class_id,
                }
            ).then(_ => {
                const ctTypeId = values.construction_type.id;
                const actOnAssignTargets = act => {
                    for (const node of hierarchyNodes(treeData)) {
                        const animatedNode = node.animated();
                        if (animatedNode.isGroupConstructionTypeAssignTarget(values)) {
                            act(animatedNode);
                        }
                    }
                }

                if (ctTypeId == null) {
                    actOnAssignTargets(node => {
                        node.clearConstructionTypeNodeInternal();
                    })
                    setTreeData([...treeData]);
                    resolve();
                } else {
                    EhApi.get(
                        `/construction-types/${ctTypeId}/preview`
                    ).then(response => {
                        actOnAssignTargets(node => {
                            node.clearConstructionTypeNodeInternal();
                            node.assignConstructionTypeNodeInternal(response.data);
                        });
                        setTreeData([...treeData]);
                        resolve();
                    }).catch(reject)
                }
            }).catch(reject)
        })
    }

    return (
        <GenericEntityFormModal
            visible={visible}
            width={612}
            formLayout={{
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
            }}
            updateVisibility={setVisible}
            attributes={emptyAssignAttributes}
            onFinish={handleFinish}
            handleError={() => {message.error("Failed to assign")}}
            title={'Group Assign Construction Type'}
            okText={"Assign"}
        />
    );
}


function AdaptedConstructionTypeSelect({value, onChange}) {
    const defaultContextValues = useContext(ConstructionTypeSelectContext);

    const handleChange = v => {
        onChange({
            name: v?.entity_id,
            id: v?.id
        });
    }

    return (
        <ConstructionTypeSelectContext.Provider value={{...defaultContextValues, extractId: r => r}}>
            <ConstructionTypeSelect value={value.name} onChange={handleChange}/>
        </ConstructionTypeSelectContext.Provider>
    );
}


function AdaptedEntityClassSelect({value, onChange}) {
    const handleChange = v => {
        onChange({
            name: v?.name,
            id: v?.id
        });
    }

    return (
        <EntityClassSelect classType={"equipment"} extractId={r => r} value={value.name} onChange={handleChange}/>
    );
}
