import {Button, Divider, Tag, Tooltip} from "antd";

export function TagFiltersList({filters, onCloseFilter, onClearAll}) {
    const clearFiltersButton = (
        <>
            <Divider type={"vertical"}/>
            <Button id="clear-all-filters-button" size="small" onClick={onClearAll}>Clear filters</Button>
        </>
    );

    const tags = filters.map((f, i) => {
        const tagSource = f.asTagSource();
        return (
            <TagFilter key={tagSource.key} source={tagSource} idx={i} onClose={_ => onCloseFilter(f)}/>
        );
    });

    return (
        <>
            {tags}
            {filters.length > 0 && clearFiltersButton}
        </>
    );
}


export function TagFilter({source, idx, onClose}) {
    const name = source.name;
    const value = source.value;
    const key = source.key;
    const color = source.color;
    const closable = source.closable;
    const tooltipProps = source.tooltipProps;

    const tag = (
        <Tag
            id={`filter-tag_${idx}`}
            key={key}
            color={color}
            onClose={e => {
                e.preventDefault();
                e.stopPropagation();
                onClose();
            }}
            closable={closable}
        >
            <b>
				<span style={{color: "black"}}>
					{name}:&nbsp;
				</span>
            </b>
            <span style={{color: "black"}}>
				{value}
			</span>
        </Tag>
    );

    return (
        <Tooltip {...tooltipProps}>
            {tag}
        </Tooltip>
    );
}


export function DefaultTagSource(name, value, color = "blue", closable = true, emptyAlias = "(empty)", maxLen = 15) {
    const key = name + value;
    name = name ? name : emptyAlias;
    value = value ? value : emptyAlias;

    function isTooLong(t) {
        return t.length > maxLen;
    }

    function trimmed(t) {
        if (isTooLong(t)) {
            t = `${t.slice(0, maxLen)}...`
        }
        return t;
    }

    const tooltipProps = {
        title: `${name}: ${value}`,
        key
    }

    if (!isTooLong(name) && !isTooLong(value)) {
        tooltipProps.visible = false;
    }

    return {
        name: trimmed(name),
        value: trimmed(value),
        color,
        key,
        tooltipProps: tooltipProps,
        closable: closable
    }
}
