import {DefaultTagSource} from "../../tag-filters-list/TagFiltersList";
import {filterOperationsMap} from "./EntityAttributeFilterEditor";
import {orDelimiter} from "../../parsers/from-url";


export function FilterEntityAttribute(options, type, tagName, operation) {
    const values = options.map(o => o.value);
    const tagLabels = options.map(o => o.tagLabel ?? o.label);

    const asTagValue = (origin) => {
        let result = '';
        if (operation === filterOperationsMap.equal) {
            result = origin;
        } else if (operation === filterOperationsMap.contains) {
            result = `*${origin}*`;
        }
        return result;
    }

    return {
        type: type,
        asRequest: () => ({type: type, args: values.filter(x => x), operation}),
        asUrlParams: () => ({[`${type}[${operation}]`]: values.join(orDelimiter)}),
        asTagSource: () => DefaultTagSource(
            tagName,
            asTagValue(tagLabels.join(" OR "))
        ),
        addTo: function (filters) {
            return [...filters.filter(f => f.type !== type), this];
        },
        removeFrom: function (filters) {
            return filters.filter(f => f !== this);
        }
    }
}
