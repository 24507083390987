import {Tooltip} from "antd";


export const endDots = (str, maxLen) => `${str.substring(0, maxLen)}...`;
export const midDots = (str, maxLen) => {
    const dots = '...';
    const strLength = str.length;
    let result;
    if (strLength - maxLen <= dots.length) {
        result = str;
    } else {
        const halfLength = Math.floor(maxLen / 2);
        result = `${str.substring(0, halfLength)}...${str.substring(strLength - halfLength, strLength)}`;
    }
    return result;
}


export function Abbreviation({value, maxLen = 16, abbreviated = midDots, tooltipProps={}}) {
    let subject;
    if (typeof value === 'string' || value instanceof String) {
        subject = {
            toString: () => value,
            render: what => what,
            tooltipTitle: () => value
        }
    } else {
        subject = value;
    }
    return (
        <Raw subject={subject} maxLen={maxLen} abbreviated={abbreviated} tooltipProps={tooltipProps}/>
    );
}


function Raw({subject, maxLen, abbreviated, tooltipProps}) {
    const strValue = subject.toString();
    const full = (
        subject.render(strValue)
    );
    const trimmed = (
        <Tooltip {...tooltipProps} title={subject.tooltipTitle()}>
            {subject.render(abbreviated(strValue, maxLen))}
        </Tooltip>
    );
    const needToTrim = strValue.length >= maxLen;
    return (
        needToTrim ? trimmed : full
    );
}
