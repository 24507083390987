import {GenericEntityFormModal} from "../../components/modals";
import {DefaultExtractedAttribute} from "../../components/hierarchy/selected-node/api/ExtractedAttribute";
import {EhApi} from "../../services";
import {message} from "antd";
import {empty} from "../../utils";
import {GeneratedName} from "../../services/GeneratedName";


const emptyBomAttributes = async () => {
	const name = await new GeneratedName("bom").value();
	const data = {
		entity_id: name,
		description: ''
	};
	return new DefaultExtractedAttribute().extract(data);
};


export function BomCreatingModal({visible, updateVisibility, onCreate=empty}) {
	const onFinish = async values => {
		return new Promise((resolve, reject) => {
			EhApi.post(
				'/boms',
				{...values}
			).then(response => {
				message.success("Created");
				onCreate(response.data);
				resolve(response)
			}).catch(reject)
		});
	}

	return (
		<GenericEntityFormModal
			visible={visible}
			updateVisibility={updateVisibility}
			title={'Create Bom'}
			onFinish={onFinish}
			handleError={() => message.error('Failed to create new BOM')}
			attributes={emptyBomAttributes}
			okText={"Create"}
		/>
	);
}
