import {Row, Spin} from "antd";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {EhApi} from "../../services";
import {EHLinkValue} from "../boms/Boms";


export function EntityLinkDispatch() {
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		dispatchBehaviour.value(searchParams).then(history.push);
	}, [location, history]);

	return (
		<Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
			<Spin spinning/>
		</Row>
	);
}


class SimpleExtractedLink {
	constructor(targetType, apiNameSpace) {
		this._targetType = targetType;
		this._ns = apiNameSpace;
	}

	ableToExtract(searchParams) {
		const entityType = searchParams.get('type');
		return entityType === this._targetType;
	}

	async value(searchParams) {
		const entityItem = searchParams.get('item');
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/${this._ns}/by-item/${entityItem}`
			).then(response => {
				resolve(EHLinkValue(this._targetType, response.data));
			}).catch(reject);
		})
	}
}


class DispatchedLink {
	constructor(redirectLinks) {
		this._redirectLinks = redirectLinks;
	}

	async value(searchParams) {
		const found = this._redirectLinks.find(l => l.ableToExtract(searchParams)) ?? {
			async value() {
				return Promise.reject('Link target not found');
			}
		};

		return found.value(searchParams);
	}
}


class FallbackLink {
	constructor(origin, fallbackValue) {
		this._origin = origin;
		this._fallbackValue = fallbackValue;
	}

	async value(searchParams) {
		return new Promise((resolve) => {
			this._origin
				.value(searchParams)
				.then(resolve)
				.catch(_ => resolve(this._fallbackValue));
		})
	}
}


const dispatchBehaviour = new FallbackLink(
	new DispatchedLink([
		new SimpleExtractedLink('bom', 'boms'),
		new SimpleExtractedLink('material', 'materials'),
		new SimpleExtractedLink('equipment', 'equipments'),
		new SimpleExtractedLink('floc', 'flocs'),
		new SimpleExtractedLink('construction_type', 'construction-types'),
		new SimpleExtractedLink('pm_plan', 'pm-plans'),
	]),
	"/"
);
