import {EhApi} from "../../../../../services";
import {SelectedBom} from "../SelectedBom";
import {BomNode} from "../../../../../components/hierarchy";

export class ApiSelectedBom extends SelectedBom {
	constructor(bomId, entity_id, setSelectedNode) {
		super();

		this._bomId = bomId;
		this.entity_id = entity_id;
		this._setSelectedNode = setSelectedNode;
	}

	async root() {
		return EhApi.get(
			`/boms/${this._bomId}/preview`
		).then(response => [BomNode(response.data, '', this._setSelectedNode)]);
	}
}
