import {useEffect, useState} from "react";
import {EhApi} from "../../services";
import {FullHeightCard} from "../../components/cards";
import {TypeTag} from "../../components/TypeTag";
import {createLink} from "../boms/Boms";
import {AsyncDataSource, TablePage} from "../../components/tables";
import {useHistory, useLocation} from "react-router-dom";
import {
    emptyFilter,
    EntityAttributeFilterFromUrl,
    FilterClassAttribute,
    FilterEntityClass,
    FilterEntityDescription,
    FilterEntityItem,
    FilterEntityTagNumber,
    FilterEntityType,
    FilterFromUrlParam,
    FilterSearchQuery,
    FiltersParsedFromUrl,
    orDelimiter,
    SearchFilters,
    SmartFilters
} from "../../components/filters";
import {EntityTypes} from "../../utils";


const parsers = [
    FilterFromUrlParam(name => name === 'q', value => FilterSearchQuery(value)),
    FilterFromUrlParam(
        name => name.startsWith('entity_type'),
        value => {
            const options = EntityTypes.asOptions();
            const values = value.split(orDelimiter);
            const filterOptions = options.filter(o => values.includes(o.value));
            if (filterOptions.length > 0) {
                return FilterEntityType(filterOptions);
            } else {
                return emptyFilter;
            }
        }
    ),
    EntityAttributeFilterFromUrl('entity_class', FilterEntityClass),
    EntityAttributeFilterFromUrl('entity_description', FilterEntityDescription),
    EntityAttributeFilterFromUrl('entity_item', FilterEntityItem),
    EntityAttributeFilterFromUrl('entity_tag_number', FilterEntityTagNumber),
    search => {
        const params = new URLSearchParams(search);
        const filters = [];
        params.forEach((value, name) => {
            const filterParts = name.match(`^class_attr\\[(.*)\\]\\[(.*)\\]$`);
            if (!filterParts) return;

            const attrName = filterParts[1];
            const operation = filterParts[2];
            filters.push(FilterClassAttribute(attrName, value.split(orDelimiter), operation));
        });
        return {
            addTo: r => filters.reduce((prev, f) => f.addTo(prev), r)
        };
    }
];


export function SearchResults() {
    const [dataSource, setDataSource] = useState(new AsyncDataSource());
    const [filters, setFilters] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        try {
            const parsed = FiltersParsedFromUrl(location.search, parsers);
            const params = new URLSearchParams(location.search);
            setPage(parseInt(params.get('page') ?? 1));
            setLimit(parseInt(params.get('limit') ?? 10));
            setFilters(parsed);
            setDataSource(new SearchDataSource(parsed));
        } catch (e) {
            console.error(e);
            history.push({
                search: ''
            });
        }
    }, [location]);

    const updateFilters = updated => {
        const fakeRestParams = {
            asUrlParams: () => ({
                page: 1, limit
            })
        }

        const searchParamsObj = [...updated, fakeRestParams]
            .filter(f => f.asUrlParams)
            .reduce((data, f) => ({...data, ...f.asUrlParams()}), {});

        history.push({
            search: new URLSearchParams(searchParamsObj).toString()
        });
    }

    const handlePageChange = (page, pageSize) => {
        const current = new URLSearchParams(location.search);
        current.set('page', page);
        current.set('limit', pageSize);
        history.push({
            search: current.toString()
        });
    };

    return (
        <FullHeightCard>
            <div style={{height: '100%'}}>
                <div style={{height: '5%'}}>
                    <SearchFilters filters={filters} onFiltersChanged={updateFilters}/>
                </div>
                <div style={{maxHeight: '95%', overflowY: 'auto'}}>
                    <SearchTable dataSource={dataSource} page={page} limit={limit} onPageChange={handlePageChange}/>
                </div>
            </div>
        </FullHeightCard>
    );
}


function SearchTable({dataSource, page, limit, onPageChange}) {
    const history = useHistory();

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: type => (
                <TypeTag _type={type}/>
            )
        },
        {
            title: 'Item',
            dataIndex: 'entity_id',
            render: (entity_id, entity) => (
                <EHLink title={entity_id} type={entity.type} entity={entity} history={history}/>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id, item) => (
                <EHLink title={'Details'} type={item.type} entity={item} history={history}/>
            )
        }
    ];

    return (
        <TablePage
            id="search-results-table"
            size="small"
            columns={columns}
            asyncDataSource={dataSource}
            page={page}
            limit={limit}
            onPageChange={onPageChange}
        />
    );
}


export function EHLink({type, entity, title, history}) {
    return <a onClick={_ => {
        createLink(`${type}`, entity, history)
    }}>
        {title}
    </a>
}


export class SearchDataSource extends AsyncDataSource {
    constructor(filters) {
        super();
        this._filters = filters;
    }

    async values(page, limit) {
        const filterParams = this._filterParams(this._filters);
        const requestBody = {filters: this._filterBody(this._filters)};

        return EhApi.post(
            '/hierarchies/current/search',
            requestBody,
            {params: {page: page, limit: limit, ...filterParams}}
        ).then(
            response => {
                return response.data.results.map(
                    el => DataItem(el.type, el.id, el.entity_id, el.description, el.path)
                );
            }
        );
    }

    _filterParams(filters) {
        return new SmartFilters(filters).asParams();
    }

    _filterBody(filters) {
        return new SmartFilters(filters).asBody();
    }
}


function DataItem(type, id, entity_id, description, path) {
    return {key: id, type, id, entity_id, description, path}
}
