import {useState} from "react";
import {Button, Form, Row, Space, Tabs} from "antd";

export function CommonFilterEditorContent({filterSchemas, editors, onOk, onCancel}) {
    const possibleTabs = editors.map(e => e.type);

    const [editedFilter, setEditedFilter] = useState(new NullEditedFilter());
    const [currentTab, setCurrentTab] = useState(possibleTabs[0] || '');

    function onEditedFilterChanged(f) {
        setEditedFilter(f);
    }

    function editorByCurrentTab() {
        return editors.find(e => e.type === currentTab);
    }

    function schemasByCurrentTab() {
        return filterSchemas.filter(schema => schema.type === currentTab);
    }

    const renderTabs = () => {
        return (
            <Tabs defaultActiveKey={currentTab}
                  onChange={t => {
                      setEditedFilter(new NullEditedFilter());
                      setCurrentTab(t);
                  }}
            >
                {editors.map(e => (
                        <Tabs.TabPane tab={e.tabName} key={e.type}>
                            {e.type === currentTab && e.render(schemasByCurrentTab(), onEditedFilterChanged)}
                        </Tabs.TabPane>
                    )
                )}
            </Tabs>
        );
    }

    const renderWithoutTabs = () => {
        return editorByCurrentTab().render(schemasByCurrentTab(), onEditedFilterChanged);
    }

    return (
        <div style={{width: "288px"}}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}}>
                {possibleTabs.length > 1 ? renderTabs() : renderWithoutTabs()}
                <Row>
                    <Space style={{marginLeft: 'auto'}}>
                        <Button
                            id="cancel-filter-button"
                            size="small"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="apply-filter-button"
                            type="primary" size="small"
                            onClick={() => {
                                if (!editedFilter.canBeAdded()) return;

                                onOk(editedFilter.toConcreteFilter());
                            }}
                            disabled={!editedFilter.canBeAdded()}
                        >
                            Add
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    );
}


export class EditedFilter {
    canBeAdded() {
        return false;
    }

    toConcreteFilter() {
        throw new Error('Unknown concrete filter');
        return {};
    }
}


export class NullEditedFilter extends EditedFilter {
    canBeAdded() {
        return false;
    }

    toConcreteFilter() {
        return {};
    }
}
