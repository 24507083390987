import {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {Resizable} from "react-resizable";
import {lastElementOf} from "../../utils";


export function WithPersistedColumns({storageKey, children, defaultColumns}) {
	const [columns, setColumns] = useState(null);

	useEffect(() => {
		let columnsToSet;
		try {
			const storedData = JSON.parse(localStorage.getItem(storageKey)) ?? [];
			const savedValOf = (col) => {
				let val = storedData.find(item => item.key === col.key)?.value ?? col.val;
				val = Math.max(col.min, val);
				return val;
			}
			columnsToSet = defaultColumns.map(el => ({
				...el,
				val: savedValOf(el)
			}));
		} catch (e) {
			columnsToSet = defaultColumns;
		}
		if (columnsToSet.length > 0) {
			const totalWidthSum = columnsToSet.map(el => el.val).reduce((p, c) => p + c);
			if (totalWidthSum < 0 || totalWidthSum > 1 || totalWidthSum !== 1) {
				columnsToSet = columnsToSet.map(el => ({...el, val: 1 / columnsToSet.length}));
			}
		}
		setColumns(columnsToSet);
	}, []);

	const handleUpdateColumns = columns => {
		setColumns(columns);
		const Item = (key, value) => ({key, value});
		localStorage.setItem(storageKey, JSON.stringify(columns.map(col => Item(col.key, col.val))));
	}

	return columns && children({
		columns,
		updateColumns: handleUpdateColumns,
	});
}


export function ResizableRow({width, height, columns, renders, updateColumns}) {
	const updateSize = (idx, value) => {
		const updated = old => {
			const freeSpace = old.slice(idx + 1).map(el => el.val - el.min).reduce((prev, cur) => prev + cur);
			const updated = Math.min(Math.max(value, old[idx].min), Math.min(old[idx].val + freeSpace, old[idx].max));
			let diff = old[idx].val - updated;
			const arr = old.slice(0, -1).map((el, i) => {
				if (i < idx) return el;
				if (i === idx) return {...el, val: updated};
				if (i > idx) {
					const freeSpace = old.slice(i + 1).map(el => el.val - el.min).reduce((prev, cur) => prev + cur);
					const updated = Math.min(Math.max(el.val + diff, el.min), Math.min(el.val + freeSpace, el.max));
					diff = (el.val + diff) - updated;
					return {...el, val: updated};
				}
			});
			return [...arr, {...lastElementOf(old), val: 1 - arr.map(el => el.val).reduce((prev, cur) => prev + cur)}];
		}
		updateColumns(updated(columns));
	}

	return (
		<Row style={{width: width + 'px', height: height + 'px'}} gutter={[8, 8]}>
			{columns.map((col, idx) => (
				<ResizableCol
					width={width}
					height={height}
					key={col.key}
					wRatio={col.val}
					onUpdateWRatio={v => updateSize(idx, v)}
					resizeHandles={idx < columns.length - 1 ? ["e"] : []}
				>
					{renders[col.key]}
				</ResizableCol>
			))}
		</Row>
	);
}


function ResizableCol({
						  width,
						  height,
						  wRatio = 0.5,
						  children,
						  onUpdateWRatio,
						  resizeHandles
					  }) {
	const onResize = (event, {size}) => {
		onUpdateWRatio(size.width / width);
	}

	return (
		<Resizable
			width={Math.floor(width * wRatio)}
			height={height}
			onResize={onResize}
			axis={"x"}
			resizeHandles={resizeHandles}
		>
			<Col style={{width: (Math.floor(width * wRatio)) + 'px', height: '100%'}}>
				<div style={{height: '100%', width: '100%'}}>
					{children}
				</div>
			</Col>
		</Resizable>
	);
}