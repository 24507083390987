import {Form} from "antd";
import {ReturnValue} from "../../Misc";
import {Abbreviation} from "../../data-renders";

export function FormItemLabel({name, ...props}) {
    return (
        <Form.Item name={name} noStyle {...props}>
            <ReturnValue map={v => <Abbreviation value={v.toString()}/>}/>
        </Form.Item>
    );
}
