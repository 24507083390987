import {CopyOutlined, FileOutlined, BlockOutlined, CalendarOutlined} from "@ant-design/icons";


export function ConstructionTypeIcon() {
	return (
		<CopyOutlined/>
	);
}


export function PmPlanIcon() {
	return (
		<CopyOutlined/>
	);
}


export function BomIcon() {
	return (
		<CopyOutlined/>
	);
}

export function MaterialIcon() {
	return (
		<FileOutlined/>
	);
}

export function EquipmentIcon() {
	return (
		<CalendarOutlined rotate={180} />
	)
}

export function FlocIcon() {
	return (
		<BlockOutlined rotate={90}/>
	);
}
