import {useState} from "react";
import {EhApi} from "../../../services";
import {AsyncDataSource, ManualPagedTable} from "../../../components/tables";


export function BomTable({onDetailsClick}) {
	const [dataSource] = useState(new BomsDataSource());

	const columns = [
		{
			title: 'Item',
			dataIndex: 'entity_id',
		},
		{
			title: 'Description',
			dataIndex: 'description',
		},
		{
			title: 'Actions',
			dataIndex: 'id',
			render: (id, item) => (
				<a onClick={_ => onDetailsClick(id, item.entity_id)}>
					Details
				</a>
			)
		}
	];

	return (
		<ManualPagedTable id={"boms-table"} size={"small"} columns={columns} asyncDataSource={dataSource}/>
	);
}


export class BomsDataSource extends AsyncDataSource {
	constructor(q="") {
		super();
		this._q = q;
	}

	async values(page, limit) {
		return EhApi.get(
			'/boms',
			{params: {page: page, limit: limit, q: this._q}}
		).then(
			response => {
				return response.data.map(el => DataItem(el.id, el.entity_id, el.description));
			}
		);
	}
}


function DataItem(id, entity_id, description) {
	return {key: id, id, entity_id, description}
}