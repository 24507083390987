import {useEffect, useState} from "react";
import {AdaptedRootDataSource, SimpleHierarchyTree} from "../../../components/hierarchy";
import {RootDataSource} from "../../../components/hierarchy/Hierarchy";


export function BomTree({setSelectedNode, selectedBom}) {
	const [rootDataSource, setRootDataSource] = useState(new RootDataSource());

	useEffect(() => {
		setRootDataSource(new AdaptedRootDataSource(selectedBom));
	}, [setSelectedNode, selectedBom]);

	return (
		<SimpleHierarchyTree rootDataSource={rootDataSource} setSelectedNode={setSelectedNode}/>
	);
}
