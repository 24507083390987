import {FilterEntityAttribute} from "../base";
import {BaseOptionsSource, FilterSchemaBaseEntityAttribute} from "../EntityAttributeFilterEditor";
import {LabelWithCount} from "../../../../Misc";


export function FilterEntityItem(options, operation) {
    return FilterEntityAttribute(options, 'entity_item', 'Item', operation);
}


export function FilterSchemaEntityItem() {
    return FilterSchemaBaseEntityAttribute(
        {value: 'item', label: 'Item'},
        BaseOptionsSource('/search-options/items', x => ({
            value: x.value,
            tagLabel: x.value,
            label: <LabelWithCount {...x}/>
        })),
        FilterEntityItem
    );
}
