import React, {useEffect, useState} from "react";
import {Alert, Button, Card, message, Row, Space, Spin} from "antd";
import {Checkbox} from "antd/es";
import {FilterableFormItem, GenericEntityForm} from "../../components/forms";
import {
    ComposedExtractedAttribute,
    PlainExtractedAttribute
} from "../../components/hierarchy/selected-node/api/ExtractedAttribute";
import {EhApi} from "../../services";


const extractedAttribute = new ComposedExtractedAttribute([
    new PlainExtractedAttribute('revision_required', key => ({
        toFormItem: () => (
            <FilterableFormItem
                valuePropName={"checked"}
                label={"Revision Required"}
                name={[key]}
                key={key}
            >
                <Checkbox/>
            </FilterableFormItem>
        )
    }))
]);


export function GlobalSettingsTab() {
    const formId = "project-settings-form";
    const [loading, setLoading] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        let cancelled = false;
        setLoading(true);

        EhApi.get(
            '/settings'
        ).then(response => {
            if (cancelled) return;
            setInitialValues(response.data);
            setLoading(false);
        }).catch(_ => {message.error("Failed to load settings")});

        return () => {
            cancelled = true;
        }
    }, []);

    const finish = values => {
        return EhApi.put(
            '/settings',
            {...values}
        ).then(response => {
            message.success("Saved");
            setHasChanges(false);
            return response;
        });
    }

    const saveButton = (
        <Row justify={"end"}>
            <Button
                disabled={!hasChanges}
                form={formId}
                key={"submit"}
                htmlType={"submit"}
                type="primary"
                size="small"
                id="accept-change-project-settings"
            >
                Save changes
            </Button>
        </Row>
    );

    return (
        <>
            <Row justify={"start"}>
                <Space direction={"vertical"} style={{width: '55%'}}>
                    {hasChanges && (
                        <Row justify={"center"}>
                            <Alert
                                style={{width: '100%'}}
                                message={"The changes are not saved yet. Press 'Save changes' for them to take effect."}
                                type={"warning"}
                            />
                        </Row>
                    )}
                    <Row justify="space-around">
                        <Card style={{width: '100%'}}>
                            <Spin spinning={loading}>
                                {!loading && (
                                    <SettingsForm
                                        id={formId}
                                        initialValues={initialValues}
                                        onFinish={finish}
                                        onValuesChange={_ => {
                                            setHasChanges(true);
                                        }}
                                        footer={saveButton}
                                    />
                                )}
                            </Spin>
                        </Card>
                    </Row>
                </Space>
            </Row>
        </>
    );
}

function SettingsForm({initialValues, id, onFinish, onValuesChange, footer}) {
    return (
        <GenericEntityForm
            layout={{
                labelCol: {span: 6},
                wrapperCol: {span: 18},
                labelAlign: "left",
                onValuesChange
            }}
            id={id}
            onFinish={onFinish}
            attributes={extractedAttribute.extract(initialValues)}
            footer={footer}
        />
    );
}
