import {EhApi} from "../../../services";
import {ConstructionTypeNode} from "../../../components/hierarchy/response-to-tree-data/response-adapters";


export class SelectedConstructionType {
    async root() {
        return [];
    }
}


export class ApiSelectedConstructionType extends SelectedConstructionType {
    constructor(constructionTypeId, setSelectedNode) {
        super();

        this._constructionTypeId = constructionTypeId;
        this._setSelectedNode = setSelectedNode;
    }

    async root() {
        return EhApi.get(
            `/construction-types/${this._constructionTypeId}/preview`
        ).then(response => [ConstructionTypeNode(response.data, '', this._setSelectedNode)]);
    }
}
