import {AsyncDataSource, ManualPagedTable} from "../async-data-source-table/AsyncDataSourceTable";


export class AdaptedSelectIdDataSource extends AsyncDataSource {
	constructor(origin) {
		super();
		this._origin = origin;
	}

	async values(page, limit) {
		return this._origin
			.values(page, limit)
			.then(data => data.map(el => DataItem(el.id, el.entity_id, el.description)));
	}
}


function DataItem(id, entity_id, description) {
	return {id, entity_id, description}
}


const defaultExtractId = record => record.id;
const defaultColumns = [
	{
		title: 'Item',
		dataIndex: 'entity_id',
	},
	{
		title: 'Description',
		dataIndex: 'description',
	},
];


export function SelectIdTable({onSelect, asyncDataSource, extractId=defaultExtractId, dataColumns, ...rest}) {
	dataColumns = dataColumns ?? defaultColumns;

	const columns = [
		...dataColumns,
		{
			title: 'Actions',
			dataIndex: 'id',
			render: (_, record) => (
				<a onClick={_ => onSelect(extractId(record))}>
					Select
				</a>
			)
		}
	];

	return (
		<ManualPagedTable
			asyncDataSource={asyncDataSource}
			columns={columns}
			rowKey={record => record.id}
			size={"small"}
			{...rest}
		/>
	);
}
