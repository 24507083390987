import {Col, Row} from "antd";
import {TagFiltersList} from "./tag-filters-list/TagFiltersList";
import {CommonFilterEditor} from "./editors/common/CommonFilterEditor";


export function Filters({filters, onFiltersChanged, filterSchemas, filtersListRender, editorRender, layoutRender}) {
    function addNewFilter(newFilter) {
        onFiltersChanged(newFilter.addTo(filters));
    }

    function clearAll() {
        onFiltersChanged([]);
    }

    function removeFilter(filter) {
        const updated = filter.removeFrom(filters);
        onFiltersChanged(updated);
    }

    function defaultLayoutRender({filtersList, editor}) {
        return (
            <Row justify={"space-between"} style={{marginBottom: '8px'}}>
                <Col span={15}>
                    {filtersList}
                </Col>
                <Col>
                    {editor}
                </Col>
            </Row>
        )
    }

    const currentLayoutRender = layoutRender ?? defaultLayoutRender;
    const editor = editorRender({filterSchemas, addNewFilter});
    const filtersList = filtersListRender({filters, clearAll, removeFilter});

    return (
        currentLayoutRender({filtersList, editor})
    )
}


export function DefaultFilters({filters, onFiltersChanged, filterSchemas, editors, ...rest}) {
    return (
        <Filters
            filters={filters}
            onFiltersChanged={onFiltersChanged}
            filterSchemas={filterSchemas}
            filtersListRender={({clearAll, removeFilter, ...rest}) => (
                <TagFiltersList
                    onClearAll={clearAll}
                    onCloseFilter={removeFilter}
                    {...rest}
                />
            )}
            editorRender={({filterSchemas, addNewFilter}) => (
                <CommonFilterEditor
                    editors={editors}
                    filterSchemas={filterSchemas}
                    onNewFilter={addNewFilter}
                />
            )}
            {...rest}
        />
    );
}
