import {ContextModal} from "./ContextModal";
import {SelectIdTableWithSearch, WithManagedDataSource} from "../../tables";
import React, {useState} from "react";
import {Spin} from "antd";


export function SelectIdContextModal({dataSourceCtor, ...rest}) {
	const [loading, setLoading] = useState(false);

	return (
		<ContextModal
			{...rest}
			width={'50vw'}
			footer={null}
			modalRender={node => (
				<Spin spinning={loading}>
					{node}
				</Spin>
			)}
		>
			{(subject, close) => (
				<WithManagedDataSource dataSourceCtor={dataSourceCtor}>
					{props => (
						<SelectIdTableWithSearch
							{...props}
							onSelect={id => {
								setLoading(true);
								return subject.handle(id)
									.then(_ => close())
									.finally(_ => {setLoading(false)})
							}}
						/>
					)}
				</WithManagedDataSource>
			)}
		</ContextModal>
	)
}
