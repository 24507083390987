import {FilterEntityAttribute} from "../base";
import {filterOperationsMap, FilterSchemaBaseEntityAttribute} from "../EntityAttributeFilterEditor";
import {EntityTypes} from "../../../../../utils";


export function FilterEntityType(options) {
    return FilterEntityAttribute(options, 'entity_type', 'Type', filterOperationsMap.equal);
}


export function FilterSchemaEntityType() {
    const optionsSource = q => new Promise((resolve) => {
        let options = EntityTypes.asOptions();
        if (q) {
            options = options.filter(x => x.value.toLowerCase().includes(q.toLowerCase()))
        }
        resolve(options);
    });

    return FilterSchemaBaseEntityAttribute(
        {label: 'Type', value: 'type'},
        optionsSource,
        FilterEntityType,
        {isCalculated: true, allowedModes: [filterOperationsMap.equal]}
    );
}
