import React, {useState} from "react";
import {FullHeightCard} from "../../components/cards";
import {HierarchySettings, NodeInfo, NullSelectedNode, SelectedNodeContent} from "../../components/hierarchy";
import {FlocTree} from "./floc-tree/FlocTree";
import {createLink} from "../boms/Boms";
import AutoSizer from "react-virtualized-auto-sizer";
import {ResizableRow, WithPersistedColumns} from "../../layouts";
import {HierarchyGroupOperationsContext} from "../../components/hierarchy/HierarchyGroupOperationsContext";
import {Dropdown, Menu, Tooltip} from "antd";
import {UnorderedListOutlinedButton} from "../../components/buttons";

export function Home() {
	const [selectedNode, setSelectedNode] = useState(new NullSelectedNode());
	const [groupOperations, setGroupOperations] = useState([]);

	const tree = (
		<HierarchyGroupOperationsContext.Provider value={setGroupOperations}>
			<FullHeightCard
				title="Asset Hierarchy"
				extra={[
                    <HierarchySettings key={"hierarchy-settings"}/>,
                    <OperationsMenu key={"hierarchy-operations"} actions={groupOperations}/>
                ]}
			>
				<FlocTree setSelectedNode={setSelectedNode}/>
			</FullHeightCard>
		</HierarchyGroupOperationsContext.Provider>
	);

	const nodeContent = (
		<SelectedNodeContent selectedNode={selectedNode} open={createLink}>
			{props => (
				<FullHeightCard title={props.nodePath}>
					<NodeInfo {...props}/>
				</FullHeightCard>
			)}
		</SelectedNodeContent>
	);

	return (
		<RowLayout right={nodeContent} left={tree}/>
	);
}


function OperationsMenu({actions}) {
	const menu = (
		<Menu
			id={'operations-menu'}
			items={actions.map(action => action.toItem().data)}
			onClick={item => {
				actions
					.sort((a, b) => a.order - b.order)
					.map(el => el.toItem())
					.filter(actionItem => actionItem.onItemClick)
					.forEach(a => a.onItemClick(item));
			}}
		/>
	);

	return (
		<Tooltip title={"Operations"}>
			<Dropdown
				overlay={menu}
				placement={"bottom"}
				trigger={["click"]}
			>
				<UnorderedListOutlinedButton id={"group-operations-trigger"}/>
			</Dropdown>
		</Tooltip>
	);
}


function RowLayout({right, left}) {
	const defaultColumns = [
		{val: 0.5, min: 0.3, max: 1.0, key: 'tree'},
		{val: 0.5, min: 0.2, max: 1.0, key: 'selected_node'}
	];

	return (
		<AutoSizer>
			{({width, height}) => (
				<WithPersistedColumns storageKey={"home"} defaultColumns={defaultColumns}>
					{props => (
						<ResizableRow
							{...props}
							width={width}
							height={height}
							renders={{
								selected_node: right,
								tree: left,
							}}
						/>
					)}
				</WithPersistedColumns>
			)}
		</AutoSizer>
	);
}
