import {Divider, Form, Input, message, Spin} from "antd";
import {SmartAttributes} from "../../hierarchy";
import {createContext, useContext, useEffect, useState} from "react";


export const FormLoadingContext = createContext();
export const FilterFormItemsContext = createContext({
	test: target => true
});

const defaultLayout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 19 },
}


export function AsyncGenericEntityForm({attributesSource, id, onFinish, layout, setLoading, footer}) {
	let currentAttributesSource;
	if (typeof attributesSource === 'function') {
		currentAttributesSource = attributesSource;
	} else {
		currentAttributesSource = async _ => attributesSource;
	}
	const [attributes, setAttributes] = useState(null);

	useEffect(() => {
		let cancelled = false;
		setLoading(true);
		currentAttributesSource()
			.then(data => {
				if (!cancelled){
					setAttributes(data)
				}
			})
			.catch(_ => {message.error("Error")})
			.finally(_ => {setLoading(false)});
		return () => {
			cancelled = true;
		}
	}, []);

	return attributes && (
		<GenericEntityForm
			id={id}
			attributes={attributes}
			onFinish={onFinish}
			layout={layout}
			footer={footer}
		/>
	);
}


export function GenericEntityForm({id, attributes, onFinish, formContainerId="form-container", layout, footer}) {
	layout = layout ?? defaultLayout;
	footer =  footer ?? (<></>);
	const [loading, setLoading] = useState(false);
	const editableAttributes = attributes.filter(el => !el.readonly);
	const smartAttributes = new SmartAttributes(editableAttributes);
	const [searchValue, setSearchValue] = useState("");

	const onFormFinish = values => {
		if (loading) return;
		onFinish(smartAttributes.toRequestValues(values))
	}

	const testFilterTarget = target => {
		let result;
		if (searchValue) {
			const normalized = str => str.toLowerCase().replaceAll(" ", "");
			result = normalized(target.label()).includes(normalized(searchValue));
		} else {
			result = true;
		}
		return result;
	}

	return (
		<Form
			{...layout}
			id={id}
			initialValues={smartAttributes.toInitialValues()}
			onFinish={onFormFinish}
		>
			<Spin spinning={loading}>
				<div style={{height: '100%'}}>
					<Header visible={smartAttributes.toFormItems().length > 2}>
						<FilterProperties onChange={setSearchValue}/>
						<Divider/>
					</Header>
					<div style={{maxHeight: '40vh', overflowY: 'auto'}} id={formContainerId}>
						<FilterFormItemsContext.Provider value={{test: testFilterTarget}}>
							<FormLoadingContext.Provider value={{loading, setLoading}}>
								{smartAttributes.toFormItems()}
							</FormLoadingContext.Provider>
						</FilterFormItemsContext.Provider>
					</div>
					{footer}
				</div>
			</Spin>
		</Form>
	);
}


function Header({visible, children}) {
	return visible && (
		children
	);
}


function FilterProperties({onChange}) {
	return (
		<div style={{backgroundColor: "white"}}>
			<DebouncedSearchInput onChange={onChange}/>
		</div>
	);
}


export function FilterableFormItem({name, label, children, ...rest}) {
	const filterContext = useContext(FilterFormItemsContext);

	const isHidden = () => {
		return !filterContext.test({
			label: () => {
				return label;
			}
		});
	}

	return (
		<Form.Item hidden={isHidden()} name={name} label={label} {...rest}>
			{children}
		</Form.Item>
	);
}


function DebouncedSearchInput({defaultValue="", onChange, delay=300}) {
	const [value, setValue] = useState(defaultValue);
	const [timeoutId, setTimeoutId] = useState(null);
	const [loading, setLoading] = useState(false);

	const updateImmediately = v => {
		setValue(v);
		onChange(v);
	}

	const updateWithDelay = v => {
		setValue(v);
		if (timeoutId !== null) {
			clearTimeout(timeoutId);
		}
		setLoading(true);
		setTimeoutId(setTimeout(() => {
			onChange(v);
			setLoading(false);
		}, delay));
	}

	return (
		<Input.Search
			loading={loading}
			placeholder="Filter properties"
			allowClear
			size={"small"}
			defaultValue={defaultValue}
			value={value}
			onPressEnter={e => {
				e.preventDefault();
				e.stopPropagation();
			}}
			onSearch={updateImmediately}
			onChange={e => updateWithDelay(e.target.value)}
		/>
	);
}
