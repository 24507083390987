import {AutoResizableRows} from "../../layouts";
import {HierarchySettings, NodeInfo, NullSelectedNode, SelectedNodeContent} from "../../components/hierarchy";
import {FullHeightCard} from "../../components/cards";
import {useEffect, useState} from "react";
import {ConstructionTypeTree} from "./construction-type-tree/ConstructionTypeTree";
import {ConstructionTypesTable} from "./types-table/ConstructionTypesTable";
import {
    ApiSelectedConstructionType,
    SelectedConstructionType
} from "./selected-construction-type/SelectedConstructionType";
import {useLocation} from "react-router-dom";
import {ConstructionTypeCreatingModal} from "./type-creating/ConstructionTypeCreatingModal";
import {LinkTextButton} from "../../components/buttons";


export function ConstructionTypes() {
    const [selectedNode, setSelectedNode] = useState(new NullSelectedNode());
    const [selectedConstructionType, setSelectedConstructionType] = useState(new SelectedConstructionType());
    const [creatingModalVisible, setCreatingModalVisible] = useState(false);
    const location = useLocation();

    const selectConstructionType = id => {
        setSelectedConstructionType(new ApiSelectedConstructionType(id));
    }

    const handleNewConstructionTypeClick = () => {
        setCreatingModalVisible(true);
    }

    useEffect(() => {
        const found = new URLSearchParams(location.search).get('construction_type')
        found && selectConstructionType(found)
    }, [location]);

    const typesList = (
        <FullHeightCard
            title={'Construction Types'}
            extra={<LinkTextButton text={"Create new Construction Type"} onClick={handleNewConstructionTypeClick}/>}
        >
            <ConstructionTypesTable onDetailsClick={selectConstructionType}/>
        </FullHeightCard>
    );

    const tree = (
        <FullHeightCard extra={<HierarchySettings/>} size={"small"}>
            <ConstructionTypeTree
                selectedConstructionType={selectedConstructionType}
                setSelectedNode={setSelectedNode}
            />
        </FullHeightCard>
    );

    const selectedNodeContent = (
        <SelectedNodeContent selectedNode={selectedNode}>
            {props => (
                <FullHeightCard title={props.nodePath}>
                    <NodeInfo {...props}/>
                </FullHeightCard>
            )}
        </SelectedNodeContent>
    );

    const handleCreate = data => {
        selectConstructionType(data.id);
    }

    return (
        <>
            <ConstructionTypeCreatingModal
                visible={creatingModalVisible}
                updateVisibility={setCreatingModalVisible}
                onCreate={handleCreate}
            />
            <Layout left={typesList} center={tree} right={selectedNodeContent}/>
        </>
    );
}


function Layout({left, center, right}) {
    return (
        <AutoResizableRows
            storageKey={"construction_types"}
            schema={{
                columns: [
                    {val: 0.3, min: 0.2, max: 1.0, key: 'table'},
                    {val: 0.3, min: 0.2, max: 1.0, key: 'tree'},
                    {val: 0.3, min: 0.2, max: 1.0, key: 'selected_node'}
                ],
                renders: {
                    table: left,
                    tree: center,
                    selected_node: right
                }
            }}
        />
    );
}
