export class SmartFilters {
    constructor(filters) {
        this._filters = filters;
    }

    asParams() {
        return this._filters.filter(f => f.asParams).reduce(
            (params, el) => ({
                ...params,
                ...el.asParams()
            }), {}
        );
    }

    asBody() {
        return this._filters.filter(f => f.asRequest).map(f => f.asRequest());
    }
}