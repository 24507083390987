import {Button} from "antd";
import {SelectOutlined, ClearOutlined, UnorderedListOutlined, LinkOutlined} from "@ant-design/icons";


export function LinkOutlinedButton({onClick, ...rest}) {
    return (
        <Button icon={<LinkOutlined />} onClick={onClick} type={"link"} {...rest}/>
    )
}


export function SelectOutlinedButton({onClick, ...rest}) {
    return (
        <Button icon={<SelectOutlined/>} onClick={onClick} type={"link"} {...rest}/>
    )
}


export function ClearOutlinedButton({onClick, ...rest}) {
    return <Button icon={<ClearOutlined/>} onClick={onClick} type={"link"} {...rest}/>
}


export function UnorderedListOutlinedButton({onClick, type="text", ...rest}) {
    return <Button icon={<UnorderedListOutlined/>} onClick={onClick} type={type} {...rest}/>
}


export function LinkButton({onClick, children, ...rest}) {
    return (
        <Button
            type={"link"}
            size={"small"}
            style={{padding: 0}}
            onClick={onClick}
            {...rest}
        >
            {children}
        </Button>
    );
}


export function LinkTextButton({onClick, text, ...rest}) {
    return (
        <LinkButton onClick={onClick} {...rest}>
            {text.toString()}
        </LinkButton>
    );
}
