import {FullHeightCard} from "../../components/cards";
import {useEffect, useState} from "react";
import {HierarchySettings, NodeInfo, NullSelectedNode, SelectedNodeContent} from "../../components/hierarchy";
import {BomTree} from "./bom-tree/BomTree";
import {NullSelectedBom} from "./bom-tree/selected-bom/SelectedBom";
import {ApiSelectedBom} from "./bom-tree/selected-bom/api/ApiSelectedBom";
import {BomTable} from "./bom-table/BomTable";
import {BomCreatingModal} from "./BomCreatingModal";
import {useLocation} from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import {ResizableRow, WithPersistedColumns} from "../../layouts";
import {LinkTextButton} from "../../components/buttons";

export const createLink = (type, item, history) => {
	history.push(EHLinkValue(type, item));
}


export const EHLinkValue = (type, item) => {
	if (type === 'floc' || type === 'equipment') {
		return `/hierarchy?node=${item.id}`;
	} else if (type === 'bom'){
		return `/boms?bom=${item.id}`;
	} else if (type === 'material') {
		return `/materials?material=${item.id}`;
	} else if (type === 'construction_type') {
		return `/construction_types?construction_type=${item.id}`;
	} else if (type === 'pm_plan') {
		return `/pm_plans?pm_plan=${item.id}`;
	} else {
		throw new Error(`Unknown entity type ${type}`);
	}
}


export function Boms() {
	const [creatingModalVisible, setCreatingModalVisible] = useState(false);
	const [selectedBom, setSelectedBom] = useState(new NullSelectedBom());
	const [selectedNode, setSelectedNode] = useState(new NullSelectedNode());
	const location = useLocation();

	const selectBom = (id, entity_id) => {
		setSelectedBom(new ApiSelectedBom(id, entity_id, setSelectedNode))
		setSelectedNode(new NullSelectedNode())
	}

	const handleNewBomClick = _ => {
		setCreatingModalVisible(true);
	}

	useEffect(() => {
		const found = new URLSearchParams(location.search).get('bom')
		found && selectBom(found)
	}, [location]);

	const bomList = (
		<FullHeightCard title={'Boms'} extra={<LinkTextButton text={"Create new BOM"} onClick={handleNewBomClick}/>}>
			<BomTable onDetailsClick={selectBom}/>
		</FullHeightCard>
	);

	const bomTree = (
		<FullHeightCard extra={<HierarchySettings/>} size={"small"}>
			<BomTree selectedBom={selectedBom} setSelectedNode={setSelectedNode}/>
		</FullHeightCard>
	);

	const selectedNodeContent = (
		<SelectedNodeContent selectedNode={selectedNode}>
			{props => (
				<FullHeightCard title={props.nodePath}>
					<NodeInfo {...props}/>
				</FullHeightCard>
			)}
		</SelectedNodeContent>
	);

	const handleCreate = data => {
		selectBom(data.id, data.entity_id);
	}

	return (
		<>
			<BomCreatingModal
				updateVisibility={setCreatingModalVisible}
				visible={creatingModalVisible}
				onCreate={handleCreate}
			/>
			<RowLayout left={bomList} center={bomTree} right={selectedNodeContent}/>
		</>
	);
}


function RowLayout({right, left, center}) {
	const defaultColumns = [
		{val: 0.5, min: 0.2, max: 1.0, key: 'table'},
		{val: 0.2, min: 0.2, max: 1.0, key: 'selected_bom'},
		{val: 0.3, min: 0.2, max: 1.0, key: 'selected_node'}
	];

	return (
		<AutoSizer>
			{({width, height}) => (
				<WithPersistedColumns storageKey={"boms"} defaultColumns={defaultColumns}>
					{props => (
						<ResizableRow
							{...props}
							width={width}
							height={height}
							renders={{
								selected_node: right,
								table: left,
								selected_bom: center
							}}
						/>
					)}
				</WithPersistedColumns>
			)}
		</AutoSizer>
	);
}
