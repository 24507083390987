import {DefaultTagSource} from "../../../tag-filters-list/TagFiltersList";

export function FilterSearchQuery(value) {
    return {
        type: 'search',
        asParams: () => ({q: value}),
        asTagSource: () => DefaultTagSource("Search", value),
        asUrlParams: () => ({q: value}),
        addTo: function (filters) {
            if (value) {
                filters = filters.filter(el => el.type !== 'search');
                return [...filters, this];
            } else {
                return filters;
            }
        },
        removeFrom: function (filters) {
            return filters.filter(f => f !== this);
        }
    }
}
