import {Modal} from "antd";
import {useContext} from "react";
import {HierarchyActionsContext} from "../HierarchyActionsContext";

export function ContextModal({title=subject => null, expectedType, children, ...rest}) {
	const {activeModal, closeActiveModal} = useContext(HierarchyActionsContext);

	return (
		<Modal
			title={title(activeModal.subject)}
			visible={activeModal.type === expectedType}
			onCancel={_ => closeActiveModal()}
			destroyOnClose={true}
			{...rest}
		>
			{children(activeModal.subject, closeActiveModal)}
		</Modal>
	);
}
