import {FilterEntityAttribute} from "../base";
import {BaseOptionsSource, FilterSchemaBaseEntityAttribute} from "../EntityAttributeFilterEditor";
import {LabelWithCount} from "../../../../Misc";


export function FilterEntityDescription(options, operation) {
    return FilterEntityAttribute(options, 'entity_description', 'Description', operation);
}


export function FilterSchemaEntityDescription() {
    return FilterSchemaBaseEntityAttribute(
        {value: 'description', label: 'Description'},
        BaseOptionsSource('/search-options/descriptions', x => ({
            value: x.value,
            tagLabel: x.value,
            label: <LabelWithCount {...x}/>
        })),
        FilterEntityDescription
    );
}
