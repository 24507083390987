import {
    ComposedExtractedAttribute,
    DefaultExtractedAttribute
} from "../../components/hierarchy/selected-node/api/ExtractedAttribute";
import {
    AssignClassOnCreation,
    classAttributes,
    ExtractedEntityClass
} from "../../components/hierarchy/selected-node/api/ExtractedEntityClass";
import {EhApi} from "../../services";
import {message} from "antd";
import {GenericEntityFormModal} from "../../components/modals";
import {EntityClassesDataSource} from "../../components/forms/data-items/entity-class-select/EntityClassSelect";
import {empty} from "../../utils";
import {GeneratedName} from "../../services/GeneratedName";


const extractedAttributes = new ComposedExtractedAttribute([
    new DefaultExtractedAttribute(),
    new ExtractedEntityClass(
        new AssignClassOnCreation(
            'pm_plan',
            {
                required: true,
                rules: [
                    () => ({
                        validator(_, value) {
                            if (value != null) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Entity Class is required'));
                        },
                    }),
                ]
            }
        )
    )
]);


async function attributes() {
    const data = {
        entity_id: '',
        description: '',
        entity_class: {
            id: null,
            name: "",
            attributes: []
        }
    }

    const fillEntityClass = new Promise((resolve, reject) => {
        new EntityClassesDataSource(
            '', 'pm_plan'
        ).values(
            1, 1
        ).then(data => {
            if (data.length === 0) {
                const msg = 'PM Class not found';
                console.error(msg);
                throw new Error(msg);
            }
            const classData = data[0];
            classAttributes(classData.id).then(attributes => {
                resolve({
                    entity_class: {
                        id: classData.id,
                        name: classData.name,
                        attributes: attributes
                    }
                })
            });
        }).catch(reject);
    });

    const fillName = new Promise((resolve, reject) => {
        new GeneratedName("pm_plan").value().then(name => {
            resolve({
                entity_id: name
            });
        }).catch(reject)
    });

    return Promise.allSettled([fillName, fillEntityClass]).then(results => {
        return extractedAttributes.extract(
            results.reduce((prev, cur) => ({...prev, ...cur.value}), data)
        );
    });
}

export function PmPlanCreatingModal({visible, updateVisibility, onCreate=empty}) {
    const onFinish = async values => {
        return new Promise((resolve, reject) => {
            EhApi.post(
                '/pm-plans',
                {...values}
            ).then(response => {
                message.success("Created");
                onCreate(response.data);
                resolve(response)
            }).catch(reject)
        });
    }

    return (
        <GenericEntityFormModal
            visible={visible}
            updateVisibility={updateVisibility}
            title={'Create PM Plan'}
            onFinish={onFinish}
            handleError={() => message.error('Failed to create new PM Plan')}
            attributes={attributes}
            okText={"Create"}
        />
    );
}
