import {empty} from "../../../utils";
import {withCheckedKey} from "../HierarchyActionsContext";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {GenericEntityFormModal} from "../../modals";
import {HierarchyGroupOperationsContext} from "../HierarchyGroupOperationsContext";
import {Input, message} from "antd";
import {SearchFilters, SmartFilters} from "../../filters";
import {ManualPagedTable} from "../../tables";
import {TypeTag} from "../../TypeTag";
import {SearchDataSource} from "../../../pages/search/Search";
import {EHLink} from "../../dropdowns";
import {ComposedExtractedAttribute, SimpleExtractedAttribute} from "../selected-node/api/ExtractedAttribute";
import {FilterableFormItem, RuleSingleValue} from "../../forms";
import {AsyncSelect} from "../../selects";
import {BaseOptionsSource} from "../../filters/eh/entity-attribute/EntityAttributeFilterEditor";
import {LabelWithCount} from "../../Misc";
import {EhApi} from "../../../services";


const extractedAttributes = new ComposedExtractedAttribute([
    new SimpleExtractedAttribute(
        model => [{
            path: 'attribute_name',
            value: model.attribute_name,
            toRequestValues: values => ({attribute_name: values.attribute_name}),
            toFormItem: () => (
                <FilterableFormItem
                    required
                    name={["attribute_name"]}
                    label={"Attribute Name"}
                    key={"attribute_name"}
                    rules={[
                        RuleSingleValue(
                            value => value != null,
                            'Attribute Name is required'
                        )
                    ]}
                >
                    <SelectAttributeName/>
                </FilterableFormItem>
            )
        }]
    ),
    new SimpleExtractedAttribute(
        model => [{
            path: 'attribute_value',
            value: model.attribute_value,
            toRequestValues: values => ({attribute_value: values.attribute_value}),
            toFormItem: () => (
                <FilterableFormItem
                    required
                    name={["attribute_value"]}
                    label={"Attribute Value"}
                    key={"attribute_value"}
                >
                    <Input size={"small"}/>
                </FilterableFormItem>
            )
        }]
    )
]);


const emptyAttributes = extractedAttributes.extract({
    attribute_name: null,
    attribute_value: null
});


export function BulkUpdateAttributeValueModal() {
    const [filters, setFilters] = useState([]);
    const setGroupOperations = useContext(HierarchyGroupOperationsContext);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handle = setGroupOperations ?? empty;
        const key = 'update-class-attribute-value';

        handle(prev => {
            return [
                ...prev,
                {
                    order: 3,
                    toItem: () => ({
                        data: {
                            key: key,
                            label: 'Bulk Update Attribute Values',
                        },
                        onItemClick: withCheckedKey(key, _ => {
                            setVisible(true);
                            setFilters([]);
                        })
                    })
                }
            ];
        });
        return () => {
            setGroupOperations(prev => prev.filter(el => el.toItem().data.key !== key));
        }
    }, [setGroupOperations]);

    const handleFinish = values => new Promise((resolve, reject) => {
        const smartFilters = new SmartFilters(filters);

        EhApi.put(
            '/hierarchies/current/preview/nodes/class-attribute-values',
            {
                filters: smartFilters.asBody(),
                ...values
            }
        ).then(resolve).catch(reject);
    });

    return (
        <GenericEntityFormModal
            visible={visible}
            width={612}
            formLayout={{
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
            }}
            updateVisibility={setVisible}
            attributes={emptyAttributes}
            onFinish={handleFinish}
            handleError={(e) => {
                message.error("Failed to replace");
            }}
            title={'Update Attribute Values'}
            okText={'Update'}
            footer={(
                <TableWithFilters filters={filters} onFiltersChanged={setFilters}/>
            )}
        />
    );
}


function TableWithFilters({filters, onFiltersChanged}) {
    const [asyncDataSource, setAsyncDataSource] = useState(new SearchDataSource([]));

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: type => (
                <TypeTag _type={type}/>
            )
        },
        {
            title: 'Item',
            dataIndex: 'entity_id',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id, item) => (
                <a onClick={_ => {
                    setTimeout(() => {
                        window.open(
                            new EHLink(item.entity_id, item.type).url(), '_blank'
                        ).focus()
                    });
                }}>
                    Open in new tab
                </a>
            )
        }
    ];

    const handleFiltersChanges = values => {
        onFiltersChanged(values);
        setAsyncDataSource(new SearchDataSource(values));
    }

    return (
        <>
            <SearchFilters filters={filters} onFiltersChanged={handleFiltersChanges}/>
            <ManualPagedTable
                asyncDataSource={asyncDataSource}
                columns={columns}
                rowKey={record => record.id}
                size={"small"}
            />
        </>
    );
}


function SelectAttributeName({value, onChange}) {
    const nameOptionsSource = useMemo(() => {
        return BaseOptionsSource(
            '/search-options/class-attribute-names',
            x => ({value: x.value, label: <LabelWithCount {...x}/>})
        );
    }, []);

    return (
        <AsyncSelect value={value} onChange={onChange} optionsSource={nameOptionsSource}/>
    );
}
