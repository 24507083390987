import {message} from "antd";
import {DefaultExtractedAttribute} from "../../../components/hierarchy/selected-node/api/ExtractedAttribute";
import {EhApi} from "../../../services";
import {GenericEntityFormModal} from "../../../components/modals";
import {empty} from "../../../utils";
import {GeneratedName} from "../../../services/GeneratedName";

const extractedAttributes = new DefaultExtractedAttribute();
const emptyAttributes = async () => {
    const name = await new GeneratedName("construction_type").value();
    const data = {
        entity_id: name,
        description: ''
    };
    return extractedAttributes.extract(data);
};


export function ConstructionTypeCreatingModal({visible, updateVisibility, onCreate=empty}) {
    const onFinish = async values => {
        return new Promise((resolve, reject) => {
            EhApi.post(
                '/construction-types',
                {...values}
            ).then(response => {
                message.success("Created");
                onCreate(response.data);
                resolve(response)
            }).catch(reject)
        });
    }

    return (
        <GenericEntityFormModal
            visible={visible}
            updateVisibility={updateVisibility}
            title={'Create Construction Type'}
            onFinish={onFinish}
            handleError={() => message.error('Failed to create new Construction Type')}
            attributes={emptyAttributes}
            okText={"Create"}
        />
    );
}
