import {useState} from "react";
import {Button, Modal, Spin} from "antd";
import {AsyncGenericEntityForm} from "../../forms";

export function GenericEntityFormModal(
	{
		okText = "Submit",
		cancelText = "Cancel",
		formId = 'generic-entity-form',
		attributes,
		visible,
		updateVisibility,
		onFinish,
		title,
		handleError = () => {
		},
		width=800,
		formLayout,
		footer
	}
) {
	const [submitting, setSubmitting] = useState(false);

	const onFormFinish = values => {
		setSubmitting(true);

		onFinish(
			values
		).then(_ => {
			updateVisibility(false);
		}).catch(err => {
			handleError(err);
		}).finally(_ => {
			setSubmitting(false);
		});
	}

	return (
		<Modal
			visible={visible}
			closable={false}
			confirmLoading={true}
			width={width}
			title={title}
			footer={[
				<Button key="cancel" onClick={_ => updateVisibility(false)}>{cancelText}</Button>,
				<Button type="primary" form={formId} key={"submit"} htmlType={"submit"}>{okText}</Button>,
			]}
			modalRender={node => (
				<Spin spinning={submitting}>
					{node}
				</Spin>
			)}
			destroyOnClose={true}
		>
			<AsyncGenericEntityForm
				id={formId}
				attributesSource={attributes}
				onFinish={onFormFinish}
				layout={formLayout}
				setLoading={setSubmitting}
				footer={footer}
			/>
		</Modal>
	);
}
