import {useEffect, useState} from "react";
import {Select, Spin} from "antd";

export function AsyncSelect({onChange, optionsSource, delay=500, ...rest}) {
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [options, setOptions] = useState([]);
    const [searchTimer, setSearchTimer] = useState(null);

    useEffect(() => {
        let isCancelled = false;
        setLoading(true);
        setSearchValue("");
        optionsSource("").then(
            data => {
                if (!isCancelled) {
                    setOptions(data);
                }
            }
        ).finally(_ => {
            if (!isCancelled) {
                setLoading(false);
            }
        });
        return () => {
            isCancelled = true;
        }
    }, [optionsSource]);

    const handleSearch = q => {
        clearTimeout(searchTimer);
        setSearchValue(q);
        setOptions([]);
        setLoading(true);
        setSearchTimer(
            setTimeout(() => {
                optionsSource(q).then(setOptions).finally(_ => {setLoading(false)});
            }, delay)
        );
    }

    return (
        <Select
            size={"small"}
            showArrow
            showSearch
            style={{width: '100%'}}
            placeholder={"(empty)"}

            {...rest}

            notFoundContent={(
                <Spin spinning={loading}>
                    Option "{searchValue}" not found
                </Spin>
            )}
            loading={loading}
            searchValue={searchValue}
            onSearch={handleSearch}
            options={options}
            onChange={onChange}
        />
    );
}
