import {useEffect, useState} from "react";
import {AdaptedRootDataSource, SimpleHierarchyTree} from "../../../components/hierarchy";
import {RootDataSource} from "../../../components/hierarchy/Hierarchy";

export function MaterialsTree({setSelectedNode, selectedMaterial}) {
    const [rootDataSource, setRootDataSource] = useState(new RootDataSource());

    useEffect(() => {
        setRootDataSource(new AdaptedRootDataSource(selectedMaterial));
    }, [setSelectedNode, selectedMaterial]);

    return (
        <SimpleHierarchyTree rootDataSource={rootDataSource} setSelectedNode={setSelectedNode}/>
    );
}
