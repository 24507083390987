export const emptyFilter = {addTo: _ => _};

// TODO avoid it
export const orDelimiter = "<~~or~~>";


export function FilterFromUrlParam(cond, ctor) {
    return search => {
        const params = new URLSearchParams(search);
        for (const param of params) {
            const name = param[0];
            const value = param[1];
            if (cond(name)) {
                return ctor(value, name);
            }
        }
        return emptyFilter;
    }
}


export function FiltersParsedFromUrl(search, parsers) {
    let result = [];
    for (const parser of parsers) {
        const filter = parser(search);
        result = filter.addTo(result);
    }
    return result;
}
