import {Layout, Menu} from "antd";
import {Link, useLocation} from "react-router-dom";
import {BomIcon, ConstructionTypeIcon, MaterialIcon, PmPlanIcon} from "../../icons";
import {ApartmentOutlined, SettingOutlined} from '@ant-design/icons';

export function Sider() {
	let location = useLocation();
	const selectedMenuItem = location.pathname.split('/')[1]
	return (
		<Layout.Sider collapsible theme={'light'}>
			<Menu mode="inline" selectedKeys={[selectedMenuItem]}>
				<Menu.Item icon={<ApartmentOutlined />} key={"hierarchy"}>
					<Link to={"/hierarchy"}>
						Asset Hierarchy
					</Link>
				</Menu.Item>
				<Menu.Item icon={<BomIcon/>} key={"boms"}>
					<Link to={"/boms"}>
						BOMs
					</Link>
				</Menu.Item>
				<Menu.Item icon={<MaterialIcon/>} key={"materials"}>
					<Link to={"/materials"}>
						Materials
					</Link>
				</Menu.Item>
				<Menu.Item icon={<ConstructionTypeIcon />} key={"construction_types"}>
					<Link to={"/construction_types"}>
						Construction Types
					</Link>
				</Menu.Item>
				<Menu.Item icon={<PmPlanIcon />} key={"pm_plans"}>
					<Link to={"/pm_plans"}>
						PM Plans
					</Link>
				</Menu.Item>
				<Menu.Item icon={<SettingOutlined />} key={"settings"}>
					<Link to={"/settings"}>
						Settings
					</Link>
				</Menu.Item>
			</Menu>
		</Layout.Sider>
	);
}
