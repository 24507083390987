import {useEffect, useState} from "react";
import {RootDataSource} from "../../../components/hierarchy/Hierarchy";
import {AdaptedRootDataSource, SimpleHierarchyTree} from "../../../components/hierarchy";


export function ConstructionTypeTree({setSelectedNode, selectedConstructionType}) {
    const [rootDataSource, setRootDataSource] = useState(new RootDataSource());

    useEffect(() => {
        setRootDataSource(new AdaptedRootDataSource(selectedConstructionType));
    }, [setSelectedNode, selectedConstructionType]);

    return (
        <SimpleHierarchyTree rootDataSource={rootDataSource} setSelectedNode={setSelectedNode}/>
    );
}
