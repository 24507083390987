import React, {createContext, useCallback, useContext, useState} from "react";
import {Modal, Tooltip} from "antd";
import {ClearOutlinedButton, SelectOutlinedButton} from "../../../buttons";
import {AdaptedSelectIdDataSource, SelectIdTableWithSearch, WithManagedDataSource} from "../../../tables";
import {ConstructionTypeDataSource} from "../../../../pages/construction-types/types-table/ConstructionTypesTable";


export const ConstructionTypeSelectContext = createContext({
    extractId: r => r.entity_id,
});


export function ConstructionTypeSelect({...rest}) {
    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    }

    const handleSelect = entityId => {
        rest.onChange(entityId);
        closeModal();
    }

    const handleClear = () => {
        rest.onChange(null);
    }

    return (
        <>
            <ConstructionTypeSelectModal visible={modalVisible} onCancel={closeModal} onSelect={handleSelect}/>
            <span>
                {rest.value}
                <Tooltip title={"Assign Construction Type"}>
                    <SelectOutlinedButton onClick={showModal}/>
                </Tooltip>
                {rest.value && (
                    <Tooltip title={"Clear Construction Type"}>
                        <ClearOutlinedButton onClick={handleClear} style={{marginLeft: '-8px'}}/>
                    </Tooltip>
                )}
            </span>
        </>
    );
}


function ConstructionTypeSelectModal({visible, onCancel, onSelect}) {
    const {extractId} = useContext(ConstructionTypeSelectContext);

    const dataSourceCtor = useCallback((q) => {
        return new AdaptedSelectIdDataSource(
            new ConstructionTypeDataSource(q)
        );
    }, []);

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={"Select Construction Type"}
            destroyOnClose={true}
            width={'50vw'}
            footer={null}
        >
            <WithManagedDataSource dataSourceCtor={dataSourceCtor}>
                {props => (
                    <SelectIdTableWithSearch
                        {...props}
                        onSelect={onSelect}
                        tableProps={{
                            extractId
                        }}
                    />
                )}
            </WithManagedDataSource>
        </Modal>
    );
}
