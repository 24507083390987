import AutoSizer from "react-virtualized-auto-sizer";
import {ResizableRow, WithPersistedColumns} from "../../layouts";
import {FullHeightCard} from "../../components/cards";
import {
    AdaptedRootDataSource,
    HierarchySettings,
    NodeInfo,
    NullSelectedNode,
    SelectedNodeContent,
    SimpleHierarchyTree
} from "../../components/hierarchy";
import {useEffect, useState} from "react";
import {RootDataSource} from "../../components/hierarchy/Hierarchy";
import {createLink} from "../boms/Boms";
import {AsyncDataSource, ManualPagedTable} from "../../components/tables";
import {LinkTextButton} from "../../components/buttons";
import {useLocation} from "react-router-dom";
import {EhApi} from "../../services";
import {PmPlanNode} from "../../components/hierarchy/response-to-tree-data/response-adapters";
import {PmPlanCreatingModal} from "./PmPlanCreatingModal";


export function PmPlans() {
    const [creatingModalVisible, setCreatingModalVisible] = useState(false);
    const [selectedNode, setSelectedNode] = useState(new NullSelectedNode());
    const [selectedPmPlan, setSelectedPmPlan] = useState({
        root: async () => []
    });
    const location = useLocation();

    const handleNewPmPlanClick = _ => {
        setCreatingModalVisible(true);
    }

    const selectPmPlan = id => {
        setSelectedPmPlan({
            root: async () => EhApi.get(
                `/pm-plans/${id}/preview`
            ).then(response => [PmPlanNode(response.data, '', setSelectedNode)])
        })
    }

    useEffect(() => {
        const found = new URLSearchParams(location.search).get('pm_plan')
        found && selectPmPlan(found)
    }, [location]);

    const table = (
        <FullHeightCard
            title={'PM Plans'}
            extra={<LinkTextButton text={"Create new PM Plan"} onClick={handleNewPmPlanClick}/>}
        >
            <Table onDetailsClick={selectPmPlan}/>
        </FullHeightCard>
    );

    const selectedNodeContent = (
        <SelectedNodeContent selectedNode={selectedNode} open={createLink}>
            {props => (
                <FullHeightCard title={props.nodePath}>
                    <NodeInfo {...props}/>
                </FullHeightCard>
            )}
        </SelectedNodeContent>
    );

    const tree = (
        <FullHeightCard extra={<HierarchySettings/>} size={"small"}>
            <Tree selectedPmPLan={selectedPmPlan} setSelectedNode={setSelectedNode}/>
        </FullHeightCard>
    );

    const handleCreate = data => {
        selectPmPlan(data.id);
    }

    return (
        <>
            <PmPlanCreatingModal
                visible={creatingModalVisible}
                updateVisibility={setCreatingModalVisible}
                onCreate={handleCreate}
            />
            <RowLayout left={table} center={tree} right={selectedNodeContent}/>
        </>
    );
}


export function Table({onDetailsClick}) {
    const [dataSource] = useState(new PmPlansDataSource());

    const columns = [
        {
            title: 'Item',
            dataIndex: 'entity_id',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: id => (
                <a onClick={_ => onDetailsClick(id)}>
                    Details
                </a>
            )
        }
    ];

    return (
        <ManualPagedTable id={"pm-plans-table"} size={"small"} columns={columns} asyncDataSource={dataSource}/>
    );
}


export function Tree({setSelectedNode, selectedPmPLan}) {
    const [rootDataSource, setRootDataSource] = useState(new RootDataSource());

    useEffect(() => {
        setRootDataSource(new AdaptedRootDataSource(selectedPmPLan));
    }, [setSelectedNode, selectedPmPLan]);

    return (
        <SimpleHierarchyTree rootDataSource={rootDataSource} setSelectedNode={setSelectedNode}/>
    );
}


export class PmPlansDataSource extends AsyncDataSource {
    constructor(q = "") {
        super();
        this._q = q;
    }

    async values(page, limit) {
        return EhApi.get(
            '/pm-plans',
            {params: {page: page, limit: limit, q: this._q}}
        ).then(
            response => response.data.map(el => DataItem(el.id, el.entity_id, el.entity_id, el.description))
        );
    }
}

function DataItem(id, entity_id, title, description) {
    return {key: id, id, entity_id, title, description};
}


function RowLayout({right, left, center}) {
    const defaultColumns = [
        {val: 0.3, min: 0.3, max: 1.0, key: 'table'},
        {val: 0.3, min: 0.2, max: 1.0, key: 'tree'},
        {val: 0.3, min: 0.2, max: 1.0, key: 'selected_node'}
    ];

    return (
        <AutoSizer>
            {({width, height}) => (
                <WithPersistedColumns storageKey={"pm_plans"} defaultColumns={defaultColumns}>
                    {props => (
                        <ResizableRow
                            {...props}
                            width={width}
                            height={height}
                            renders={{
                                selected_node: right,
                                table: left,
                                tree: center
                            }}
                        />
                    )}
                </WithPersistedColumns>
            )}
        </AutoSizer>
    );
}
