import {GenericEntityFormModal} from "../../components/modals";
import {
	ComposedExtractedAttribute,
	DefaultExtractedAttribute
} from "../../components/hierarchy/selected-node/api/ExtractedAttribute";
import {EhApi} from "../../services";
import {message} from "antd";
import {
	AssignClassOnCreation,
	ExtractedEntityClass
} from "../../components/hierarchy/selected-node/api/ExtractedEntityClass";
import {empty} from "../../utils";
import {GeneratedName} from "../../services/GeneratedName";


const extractedAttributes = new ComposedExtractedAttribute([
	new DefaultExtractedAttribute(),
	new ExtractedEntityClass(
		new AssignClassOnCreation('material')
	)
]);


const emptyMaterialAttributes = async () => {
	const name = await new GeneratedName("material").value();
	const data = {
		entity_id: name,
		description: '',
		entity_class: {
			id: null,
			name: null,
			attributes: []
		}
	};
	return extractedAttributes.extract(data);
};


export function MaterialCreatingModal({visible, updateVisibility, onCreate=empty}) {
	const onFinish = async values => {
		return new Promise((resolve, reject) => {
			EhApi.post(
				'/materials',
				{...values}
			).then(response => {
				message.success("Created");
				onCreate(response.data);
				resolve(response)
			}).catch(reject)
		});
	}

	return (
		<GenericEntityFormModal
			visible={visible}
			updateVisibility={updateVisibility}
			title={'Create Material'}
			onFinish={onFinish}
			handleError={() => message.error('Failed to create new Material')}
			attributes={emptyMaterialAttributes}
			okText={"Create"}
		/>
	);
}
