import {AdaptedSelectIdDataSource} from "../../tables";
import {SelectIdContextModal} from "./SelectIdContextModal";
import {BomsDataSource} from "../../../pages/boms/bom-table/BomTable";
import {useCallback} from "react";

export function AssignBomModal() {
	const dataSourceCtor = useCallback((q) => {
		return new AdaptedSelectIdDataSource(
			new BomsDataSource(q)
		);
	}, []);

	return (
		<SelectIdContextModal
			dataSourceCtor={dataSourceCtor}
			title={subject => `Assign Bom to: ${subject.title}`}
			expectedType={'assign_bom'}
		/>
	);
}