import {useState} from "react";
import {AddFilterButton} from "./AddFilterButton";
import {CommonFilterEditorContent} from "./CommonFilterEditorContent";


export function CommonFilterEditor({filterSchemas, editors, onNewFilter}) {
    const [visible, setVisible] = useState(false);

    const content = (
        <CommonFilterEditorContent
            editors={editors}
            filterSchemas={filterSchemas}
            onOk={f => {
                onNewFilter(f);
                setVisible(false);
            }}
            onCancel={() => setVisible(false)}
        />
    );

    return (
        <AddFilterButton
            onVisibleChange={v => setVisible(v)}
            contentVisible={visible}
            content={content}
        />
    );
}