import React, {useState} from "react";
import {Input} from "antd";
import {SelectIdTable} from "./SelectIdTable";


export function WithManagedDataSource({dataSourceCtor, children}) {
    const [dataSource, setDataSource] = useState(dataSourceCtor(""));

    const handleQueryChange = (q) => {
        setDataSource(dataSourceCtor(q));
    }

    return (
        children({asyncDataSource: dataSource, onQueryChange: handleQueryChange})
    );
}


export function SelectIdTableWithSearch({onSelect, asyncDataSource, onQueryChange, tableProps={}}) {
    const [queryInputText, setQueryInputText] = useState("");

    return (
        <>
            <Input.Search
                id="table-search-box"
                onChange={e => setQueryInputText(e.target.value)}
                value={queryInputText}
                enterButton
                onSearch={() => {onQueryChange(queryInputText)}}
                style={{marginBottom: '16px'}}
            />
            <SelectIdTable
                onSelect={onSelect}
                asyncDataSource={asyncDataSource}
                {...tableProps}
            />
        </>
    );
}
