import {createContext, useCallback, useMemo, useState} from "react";
import {message} from "antd";

export const HierarchyActionsContext = createContext();

export function HierarchyActionsProvider({treeData, setTreeData, children, setIsLoading}) {
	const [cutTarget, setCutTarget] = useState(null);
	const [activeModal, setActiveModal] = useState(NullActiveModal());

	const possibleFlocActions = useMemo(() => (
		[
			AssignBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
			AssignMaterialAction(setActiveModal, setIsLoading, treeData, setTreeData),
			AssignPmPlanAction(setActiveModal, setIsLoading, treeData, setTreeData),
			Divider(),
			CreateEquipmentAction(setActiveModal, setIsLoading, treeData, setTreeData),
			CreateFlocAction(setActiveModal, setIsLoading, treeData, setTreeData),
			Divider(),
			CutAction(cutTarget, setCutTarget),
			PasteAction(setIsLoading, setTreeData, treeData, cutTarget, setCutTarget),
			Divider(),
			CopyBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
		]
	), [setIsLoading, treeData, cutTarget, setTreeData]);

	const possibleEquipmentActions = useMemo(() => (
		[
			AssignBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
			AssignMaterialAction(setActiveModal, setIsLoading, treeData, setTreeData),
			AssignPmPlanAction(setActiveModal, setIsLoading, treeData, setTreeData),
			Divider(),
			CreateEquipmentAction(setActiveModal, setIsLoading, treeData, setTreeData),
			Divider(),
			CutAction(cutTarget, setCutTarget),
			PasteAction(setIsLoading, setTreeData, treeData, cutTarget, setCutTarget),
			Divider(),
			CopyBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
		]
	), [setIsLoading, treeData, cutTarget, setTreeData]);

	const possibleMaterialActions = useMemo(() => (
		[
			CreateAsMMParent(setActiveModal, setIsLoading, treeData, setTreeData),
			AssignMaterialAction(setActiveModal, setIsLoading, treeData, setTreeData, 'Assign MM child'),
			Divider(),
			RemoveItselfAction(
				setIsLoading,
				setTreeData,
				treeData,
				'remove_material_from',
				parentTitle => `Material removed from ${parentTitle}`,
				_ => 'Failed to remove material'
			),
		]
	), [setIsLoading, setTreeData, treeData]);

	const possibleBomActions = useMemo(() => (
		[
			AssignBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
			AssignMaterialAction(setActiveModal, setIsLoading, treeData, setTreeData),
			Divider(),
			RemoveItselfAction(
				setIsLoading,
				setTreeData,
				treeData,
				'remove_bom_from',
				parentTitle => `Bom removed from ${parentTitle}`,
				_ => 'Failed to remove bom'
			),
			Divider(),
			CopyBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
		]
	), [setIsLoading, treeData, setTreeData]);

	const possibleConstructionTypeActions = useMemo(() => (
		[
			AssignBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
			AssignMaterialAction(setActiveModal, setIsLoading, treeData, setTreeData),
			AssignPmPlanAction(setActiveModal, setIsLoading, treeData, setTreeData),
			Divider(),
			CopyBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
		]
	), [setIsLoading, treeData, setTreeData]);

	const possiblePmPlanActions = useMemo(() => ([
		AssignBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
		AssignMaterialAction(setActiveModal, setIsLoading, treeData, setTreeData),
		Divider(),
		RemoveItselfAction(
			setIsLoading,
			setTreeData,
			treeData,
			'remove_pm_plan_from',
			parentTitle => `Pm Plan removed from ${parentTitle}`,
			_ => 'Failed to remove PM Plan'
		),
		Divider(),
		CopyBomAction(setActiveModal, setIsLoading, treeData, setTreeData),
	]), [setIsLoading, treeData, setTreeData]);

	const closeActiveModal = useCallback(() => {
		setActiveModal(NullActiveModal());
	}, []);

	return (
		<HierarchyActionsContext.Provider
			value={{
				treeData,
				setTreeData,
				possibleFlocActions,
				possibleEquipmentActions,
				possibleBomActions,
				possibleMaterialActions,
				activeModal,
				closeActiveModal,
				possibleConstructionTypeActions,
				possiblePmPlanActions
			}}
		>
			{children}
		</HierarchyActionsContext.Provider>
	);
}


function withAppliedPatch(origin, treeData) {
	return function () {
		return origin(...arguments).then(patch => patch(treeData));
	};
}


function withLoading(origin, setLoading) {
	return function () {
		setLoading(true);
		return origin(...arguments).finally(_ => setLoading(false));
	}
}


function withUpdatedTreeData(origin, treeData, updateTreeData) {
	return function () {
		return origin(...arguments).then(_ => updateTreeData([...treeData]));
	}
}


function ModalSubject(subject, setIsLoading, treeData, setTreeData) {
	return {
		...subject,
		handle: withUpdatedTreeData(
			withLoading(
				withAppliedPatch(
					subject.handle,
					treeData
				),
				setIsLoading
			),
			treeData,
			setTreeData
		)
	}
}


function Group(itemProps, childrenActions) {
	return {
		toItem: source => ({
			data: {
				children: childrenActions.map(action => action.toItem(source).data),
				...itemProps
			},
			onItemClick: item => {
				childrenActions.map(action => action.toItem(source)).forEach(action => action.onItemClick(item));
			}
		})
	}
}


export function withCheckedKey(targetKey, callback) {
	return function (item) {
		if (item.key === targetKey) {
			callback();
		}
	}
}


function Divider() {
	return {
		toItem: _ => ({
			data: {
				type: 'divider'
			}
		})
	}
}


function CutAction(cutTarget, setCutTarget) {
	const onSelect = source => {
		if (cutTarget) cutTarget.uncut();

		setCutTarget(source);
		source.cut();
	}

	return {
		toItem: source => ({
			data: {
				label: 'Cut',
				key: 'cut',
				disabled: !source.isParented()
			},
			onItemClick: withCheckedKey('cut', _ => onSelect(source))
		}),
	}
}


function PasteAction(setIsLoading, setTreeData, treeData, cutTarget, setCutTarget) {
	const onSelect = pasteTarget => {
		setIsLoading(true);
		cutTarget.reparentWith(
			pasteTarget
		).then(patch => {
			return patch(treeData).then(_ => {
				cutTarget.uncut();
				setCutTarget(null);
				setTreeData([...treeData]);
				message.success('Parent updated');
			})
		}).catch(
			_ => {
				message.error('Failed to update parent')
			}
		).finally(_ => {
			setIsLoading(false)
		});
	}

	return {
		toItem: source => ({
			data: {
				label: 'Paste',
				key: 'paste',
				disabled: function (source) {
					if (!cutTarget) return true;
					return !source.ableToAdopt(cutTarget);
				}(source)
			},
			onItemClick: withCheckedKey('paste', _ => onSelect(source))
		})
	}
}


function RemoveItselfAction(setIsLoading, setTreeData, treeData, key, successMessage, failedMessage) {
	const onSelect = removeTarget => {
		const parentTitle = removeTarget.parentTitle();
		setIsLoading(true);
		const stopLoading = () => setIsLoading(false);
		const onSuccessRemove = () => {
			setTreeData([...treeData]);
			message.success(successMessage(parentTitle));
		}
		const onFailedRemove = () => {
			setTreeData([...treeData]);
			message.error(failedMessage(parentTitle));
		}
		removeTarget.remove()
			.then(patch => patch(treeData))
			.then(onSuccessRemove)
			.catch(onFailedRemove)
			.finally(stopLoading);
	}

	return {
		toItem: source => ({
			data: {
				key: key,
				label: `Remove this from ${source.parentTitle()}`,
				disabled: !source.isParented(),
			},
			onItemClick: withCheckedKey(key, _ => onSelect(source))
		}),
	}
}


function AssignPmPlanAction(setActiveModal, setIsLoading, treeData, setTreeData) {
	const onSelect = source => {
		const subject = source.asAssignPmPlanSubject();

		setActiveModal({
			type: 'assign_pm_plan',
			subject: ModalSubject(subject, setIsLoading, treeData, setTreeData)
		});
	}

	return {
		toItem: source => ({
			data: {
				key: 'assign_pm_plan',
				label: 'Assign PM Plan',
				disabled: false,
			},
			onItemClick: withCheckedKey('assign_pm_plan', _ => onSelect(source))
		}),
	}
}


function AssignBomAction(setActiveModal, setIsLoading, treeData, setTreeData) {
	const onSelect = source => {
		const subject = source.asAssignBomSubject();

		setActiveModal({
			type: 'assign_bom',
			subject: ModalSubject(subject, setIsLoading, treeData, setTreeData)
		});
	}

	return {
		toItem: source => ({
			data: {
				key: 'assign_bom',
				label: 'Assign BOM',
				disabled: false,
			},
			onItemClick: withCheckedKey('assign_bom', _ => onSelect(source))
		}),
	}
}

function CopyBomAction(setActiveModal, setIsLoading, treeData, setTreeData) {
	const onSelect = source => {
		const subject = source.asCopyBomSubject();

		setActiveModal({
			type: 'copy_bom',
			subject: ModalSubject(subject, setIsLoading, treeData, setTreeData)
		});
	}

	return {
		toItem: source => ({
			data: {
				key: 'copy_bom',
				label: 'Assign copy of BOM',
				disabled: false,
			},
			onItemClick: withCheckedKey('copy_bom', _ => onSelect(source))
		}),
	}
}

function AssignMaterialAction(setActiveModal, setIsLoading, treeData, setTreeData, label) {
	label = label ?? 'Assign Material';
	const onSelect = source => {
		const subject = source.asAssignMaterialSubject();

		setActiveModal({
			type: 'assign_material',
			subject: ModalSubject(subject, setIsLoading, treeData, setTreeData)
		});
	}

	return {
		toItem: source => ({
			data: {
				key: 'assign_material',
				label: label,
				disabled: false
			},
			onItemClick: withCheckedKey('assign_material', _ => onSelect(source))
		}),
	}
}


function CreateAsMMParent(setActiveModal, setIsLoading, treeData, setTreeData) {
	const onSelect = source => {
		const subject = source.asCreateAsMMParentSubject();

		setActiveModal({
			type: 'create_as_mm_parent',
			subject: ModalSubject(subject, setIsLoading, treeData, setTreeData)
		});
	}

	return {
		toItem: source => ({
			data: {
				key: 'create_as_mm_parent',
				label: 'Create as MM parent',
				disabled: false
			},
			onItemClick: withCheckedKey('create_as_mm_parent', _ => onSelect(source))
		}),
	}
}


function CreateFlocAction(setActiveModal, setIsLoading, treeData, setTreeData) {
	const onSelect = source => {
		const subject = source.asCreateFlocSubject();

		setActiveModal({
			type: 'create_floc',
			subject: ModalSubject(subject, setIsLoading, treeData, setTreeData)
		});
	}

	return {
		toItem: source => ({
			data: {
				key: 'create_floc',
				label: 'Create new FLOC',
				disabled: false,
			},
			onItemClick: withCheckedKey('create_floc', _ => onSelect(source))
		})
	}
}


function CreateEquipmentAction(setActiveModal, setIsLoading, treeData, setTreeData) {
	const onSelect = source => {
		const subject = source.asCreateEquipmentSubject();

		setActiveModal({
			type: 'create_equipment',
			subject: ModalSubject(subject, setIsLoading, treeData, setTreeData)
		});
	}

	return {
		toItem: source => ({
			data: {
				key: 'create_equipment',
				label: 'Create new Equipment',
				disabled: false
			},
			onItemClick: withCheckedKey('create_equipment', _ => onSelect(source))
		})
	}
}


function NullActiveModal() {
	return {
		type: '',
		subject: {
			title: '',
			handle() {
			}
		}
	}
}