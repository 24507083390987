import {Input} from "antd";
import Descriptions from "antd/es/descriptions";
import {NodePath} from "../response-to-tree-data/response-adapters";
import {FilterableFormItem} from "../../forms";
import {NonNegativeIntInput} from "../../inputs";

export class SmartAttributes {
	constructor(attributes) {
		this._attributes = attributes;
	}

	_formInputByAttr(attr) {
		if (attr.type === 'text') {
			return <Input/>
		} else if (attr.type === 'non_negative_int') {
			return (<NonNegativeIntInput/>);
		} else {
			throw new Error(`Unsupported attribute type ${attr.type}`);
		}
	}

	toInitialValues() {
		const result = {};
		this._attributes.forEach(el => result[el.path] = el.value);
		return result;
	}

	toFormItems() {
		const editableAttributes = this._attributes.filter(el => !el.readonly);

		const defaultToFormItem = attr => {
			return () => (
				<FilterableFormItem key={attr.path} name={attr.path} label={attr.label} rules={attr.rules}>
					{this._formInputByAttr(attr)}
				</FilterableFormItem>
			)
		}

		const result = editableAttributes.map(attr => {
			const toFormItem = attr.toFormItem ?? defaultToFormItem(attr);
			return toFormItem();
		});

		return result;
	}

	toDescriptionItems() {
		const defaultToDescriptionsItem = attr => {
			return () => (
				<Descriptions.Item label={attr.label} key={attr.path}>
					{attr.value}
				</Descriptions.Item>
			)
		};

		const result = this._attributes.map(attr => {
			const toDescriptionsItem = attr.toDescriptionsItem ?? defaultToDescriptionsItem(attr);
			return toDescriptionsItem();
		});

		return result;
	}

	hasEntityId() {
		return this._attributes.find(attr => attr.path === 'entity_id') != null;
	}

	attrValueByPath(path) {
		return this._attributes.find(attr => attr.path === path).value;
	}

	entityId() {
		return this._attributes.find(attr => attr.path === 'entity_id').value;
	}

	description() {
		return this._attributes.find(attr => attr.path === 'description').value;
	}

	actualizeNodePath(nodePath) {
		if (!this.hasEntityId()) return new NodePath();
		return nodePath.updateTitle(this.entityId());
	}

	actualizeTreeItemFields(treeItem) {
		const defaultFor = (attr) => {
			return _ => {}
		}

		this._attributes.forEach(attr => {
			const actualizeTreeItemFields = attr.actualizeTreeItemFields ?? defaultFor(attr);
			actualizeTreeItemFields(treeItem);
		});
	}

	toRequestValues(values) {
		const defaultToRequestAttributes = attr => {
			return (v) => {
				const result = {}
				result[attr.path] = v[attr.path]
				return result;
			};
		}
		const result = this._attributes.reduce((prev, attr) => {
			const toRequestValues = attr.toRequestValues ?? defaultToRequestAttributes(attr);
			return {...prev, ...toRequestValues(values)};
		}, {});
		return result;
	}

	toTabs() {
		const defaultFor = attr => {
			return () => [];
		}

		const result = this._attributes.flatMap(attr => {
			const toTab = attr.toTab ?? defaultFor(attr);
			return toTab();
		})
		return result;
	}
}
