import {useRef} from "react";
import {Hierarchy, RootDataSource} from "./Hierarchy";


export function SimpleHierarchyTree({setSelectedNode, rootDataSource}) {
    const hierarchyRef = useRef();

    return (
        <Hierarchy
            ref={hierarchyRef}
            setSelectedNode={setSelectedNode}
            rootDataSource={rootDataSource}
            emptyDataRender={<></>}
            onRootLoaded={data => {
                if (hierarchyRef.current && data.length > 0) hierarchyRef.current.scrollTo(data[0].id);
            }}
        />
    );
}


export class AdaptedRootDataSource extends RootDataSource {
    constructor(rootSource) {
        super();
        this._rootSource = rootSource;
    }

    async value() {
        return this._rootSource.root().then(data => ({
            data: data,
            expandedKeys: []
        }))
    }
}
