import {message, Upload, Button} from "antd";
import {API_URL} from "../../constants";
import {Auth} from "@digatex/digatex-ui-lib"
import {UploadOutlined} from "@ant-design/icons"

export function ImportClassLibraryTab() {
  const uploadProps = {
    name: 'file',
    accept: '.xlsx',
    action: API_URL + `/class-library/import`,
    headers: Auth.headers(),
    data: {},
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`Class Library was imported successfully`);
      } else if (info.file.status === 'error') {
        message.error(`Failed to import Class Library`);
      }
    },
  };

  return (
      <Upload {...uploadProps}>
        <Button><UploadOutlined/> Upload from excel file</Button>
      </Upload>
  )
}