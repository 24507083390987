import {SelectedNode, SelectedNodeWrap} from "../SelectedNode";
import {EhApi} from "../../../../services";
import {
	ComposedExtractedAttribute,
	DefaultExtractedAttribute,
	ExtractedAttribute, ExtractedQuantity,
	ExtractedTagNumber
} from "./ExtractedAttribute";
import {AssignClassOnCreation, AssignClassOnEdit, ExtractedEntityClass} from "./ExtractedEntityClass";
import {ExtractedConstructionType} from "./ExtractedConstructionType";
import {AsyncDataSource} from "../../../tables";
import {SmartAttributes} from "../SmartAttributes";
import {BaseNodeLinks, DMLink, EHLink, IHLink, KHSearchLink, WBLink} from "../../../dropdowns";


class BaseApiSelectedNode extends SelectedNode {
	constructor(
		id,
		path,
		apiNamespace,
		extractedAttributes = new ExtractedAttribute(),
		links
	) {
		super();
		this._id = id;
		this._path = path;
		this._apiNamespace = apiNamespace;
		this._extractedAttributes = extractedAttributes;
		this._links = links;
	}

	id() {
		return this._id;
	}

	logs() {
		return {
			hidden: false,
			objectId: this._id
		};
	}

	async namedPath() {
		return {
			value: this._path,
			open: path => {}
		};
	}

	async attributes() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				this._apiNamespace
			).then(response => {
				resolve(this._extractedAttributes.extract(response.data));
			}).catch(reject);
		});
	}

	async edit(values) {
		return new Promise((resolve, reject) => {
			EhApi.put(
				this._apiNamespace,
				{...values}
			).then(response => {
				resolve(this._extractedAttributes.extract(response.data));
			}).catch(reject);
		});
	}

	async links() {
		return this._links();
	}
}


export class ApiSelectedEquipmentNode extends BaseApiSelectedNode {
	constructor(id, path) {
		super(
			id,
			path,
			`/equipments/${id}`,
			new ComposedExtractedAttribute([
				new DefaultExtractedAttribute(),
				new ExtractedConstructionType(),
				new ExtractedTagNumber(),
				new ExtractedEntityClass(new AssignClassOnCreation('equipment'))
			]),
			() => LinksFromAttributes(
				() => this.attributes(),
				'equipment',
				attrs => [new KHSearchLink(new SmartAttributes(attrs).attrValueByPath('tag_number'))]
			)
		);
	}

	pmPlans() {
		return {
			hidden: false,
			dataSource: new NestedPmPlansDataSource('equipments', this._id)
		}
	}
}


export class ApiSelectedPmPlanNode extends BaseApiSelectedNode {
	constructor(id, path) {
		super(
			id,
			path,
			`/pm-plans/${id}`,
			new ComposedExtractedAttribute([
				new DefaultExtractedAttribute(),
				new ExtractedEntityClass(new AssignClassOnEdit())
			]),
			() => LinksFromAttributes(
				() => this.attributes(),
				'pm_plan',
				attrs => [new KHSearchLink(new SmartAttributes(attrs).entityId())]
			)
		);
	}

	async parents() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/pm-plans/${this._id}/parents`,
			).then(response => {
				resolve(response.data);
			}).catch(_ => reject());
		})
	}
}


export class ApiSelectedConstructionTypeNode extends BaseApiSelectedNode {
	constructor(id, path) {
		super(
			id,
			path,
			`/construction-types/${id}`,
			new ComposedExtractedAttribute([
				new DefaultExtractedAttribute(),
			]),
			() => LinksFromAttributes(
				() => this.attributes(),
				'construction_type',
				attrs => [new KHSearchLink(new SmartAttributes(attrs).entityId())]
			)
		);
	}

	async parents() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/construction-types/${this._id}/parents`,
			).then(response => {
				resolve(response.data);
			}).catch(_ => reject());
		})
	}

	pmPlans() {
		return {
			hidden: false,
			dataSource: new NestedPmPlansDataSource('construction-types', this._id)
		}
	}
}


export class ApiSelectedFlocNode extends BaseApiSelectedNode {
	constructor(id, path) {
		super(
			id,
			path,
			`/flocs/${id}`,
			new ComposedExtractedAttribute([
				new DefaultExtractedAttribute(),
				new ExtractedConstructionType()
			]),
			() => LinksFromAttributes(
				() => this.attributes(),
				'floc',
				attrs => [new KHSearchLink(new SmartAttributes(attrs).entityId())]
			)
		);
	}

	pmPlans() {
		return {
			hidden: false,
			dataSource: new NestedPmPlansDataSource('flocs', this._id)
		}
	}
}


export class ApiSelectedBomNode extends BaseApiSelectedNode {
	constructor(id, path) {
		super(
			id,
			path,
			`/boms/${id}`,
			new DefaultExtractedAttribute(),
			() => LinksFromAttributes(
				() => this.attributes(),
				'bom',
				attrs => [new KHSearchLink(new SmartAttributes(attrs).entityId())]
			)
		);
	}

	async parents() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/boms/${this._id}/parents`,
			).then(response => {
				resolve(response.data);
			}).catch(_ => reject());
		})
	}
}


class BaseApiSelectedMatNode extends BaseApiSelectedNode {
	constructor(id, path, ns, attributes) {
		super(
			id,
			path,
			ns,
			attributes,
			() => LinksFromAttributes(
				() => this.attributes(),
				'material',
				attrs => [new KHSearchLink(new SmartAttributes(attrs).entityId())]
			)
		);
	}

	async parents() {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/materials/${this._id}/parents`,
			).then(response => {
				resolve(response.data);
			}).catch(_ => reject());
		});
	}
}


export class ApiSelectedMatNode extends BaseApiSelectedMatNode {
	constructor(id, path) {
		super(
			id,
			path,
			`/materials/${id}`,
			new ComposedExtractedAttribute([
				new DefaultExtractedAttribute(),
				new ExtractedEntityClass(new AssignClassOnCreation('material'))
			])
		);
	}
}


export class ApiSelectedMatChildNode extends BaseApiSelectedMatNode {
	constructor(id, path, parentId) {
		super(
			id,
			path,
			`/materials/${id}/as-child-of/${parentId}`,
			new ComposedExtractedAttribute([
				new DefaultExtractedAttribute(),
				new ExtractedQuantity(parentId),
				new ExtractedEntityClass(new AssignClassOnCreation('material'))
			])
		);
	}
}


export class TreeSyncSelectedNode extends SelectedNodeWrap {
	constructor(animatedNode, treeData, setTreeData, setIsLoading, scrollRef, origin) {
		super(origin);
		this._treeData = treeData;
		this._setTreeData = setTreeData;
		this._setIsLoading = setIsLoading;
		this._animatedNode = animatedNode;
		this._scrollRef = scrollRef;
	}

	async namedPath() {
		return this._origin.namedPath().then(namedPath => ({
			value: namedPath.value,
			open: path => this._scrollRef.current.scrollToByPath(path)
		}));
	}

	async edit(data) {
		this._setIsLoading(true);
		return this._origin.edit(data).then(result => {
			const applyPatch = patch => {
				patch(
					this._treeData
				).then(_ => {
					this._setTreeData([...this._treeData])
				})
			}
			this._animatedNode.syncEditing(result).then(applyPatch);
			return result;
		}).finally(_ => this._setIsLoading(false));
	}
}


class NestedPmPlansDataSource extends AsyncDataSource {
	constructor(ns, id) {
		super();
		this._ns = ns;
		this._id = id;
	}

	async values(page, limit) {
		return new Promise((resolve, reject) => {
			EhApi.get(
				`/${this._ns}/${this._id}/pm-plans`,
				{page, limit}
			).then(response => {
				const attrValueOrNull = (el, attrName) => {
					const attr =  el.entity_class.attributes.find(a => a.name === attrName) ?? {value: null};
					return attr.value;
				}
				const maintTextOf = el => attrValueOrNull(el, "Maint Item Text");
				resolve(response.data.map(el => ({
					id: el.id,
					sortField: el.entity_id,
					description: el.description,
					maintText: maintTextOf(el),
				})))
			}).catch(reject)
		});
	}
}


function CommonLinks(entityId, entityType) {
	return [
		new DMLink(entityId),
		new IHLink(entityId),
		new EHLink(entityId, entityType),
		new WBLink(entityId)
	];
}


function LinksFromAttributes(attributesSource, entityType, rest) {
	return new Promise((resolve, reject) => {
		attributesSource().then(attributes => {
			const smartAttributes = new SmartAttributes(attributes);
			resolve(
				new BaseNodeLinks(
					[
						...CommonLinks(smartAttributes.entityId(), entityType),
						...rest(attributes)
					]
				)
			)
		}).catch(reject);
	});
}
