import React, {useCallback, useState} from "react";
import {Modal, Tooltip} from "antd";
import {ClearOutlinedButton, SelectOutlinedButton} from "../../../buttons";
import {AsyncDataSource, SelectIdTableWithSearch, WithManagedDataSource} from "../../../tables";
import {EhApi} from "../../../../services";


export function EntityClassSelect({classType, extractId, ...rest}) {
    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    }

    const handleSelect = value => {
        rest.onChange(value);
        closeModal();
    }

    const handleClear = () => {
        rest.onChange(null);
    }

    return (
        <>
            <EntityClassSelectModal
                visible={modalVisible}
                onCancel={closeModal}
                onSelect={handleSelect}
                classType={classType}
                extractId={extractId}
            />
            <span>
                {rest.value}
                <Tooltip title={"Select Entity Class"}>
                    <SelectOutlinedButton onClick={showModal}/>
                </Tooltip>
                {rest.value && (
                    <Tooltip title={"Clear Entity Class"}>
                        <ClearOutlinedButton onClick={handleClear} style={{marginLeft: '-8px'}}/>
                    </Tooltip>
                )}
            </span>
        </>
    );
}


export class EntityClassesDataSource extends AsyncDataSource {
    constructor(q, classType) {
        super();
        this._q = q;
        this._classType = classType;
    }

    async values(page, limit) {
        return new Promise((resolve, reject) => {
            EhApi.get(`/class-library/${this._classType}`, {params: {q: this._q, page, limit}})
                .then(response => response.data)
                .then(resolve)
                .catch(reject);
        })
    }
}


function EntityClassSelectModal({visible, onCancel, onSelect, classType, extractId}) {
    const dataSourceCtor = useCallback((q) => {
        return new EntityClassesDataSource(q, classType);
    }, []);

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={"Select Class"}
            destroyOnClose={true}
            width={'50vw'}
            footer={null}
        >
            <WithManagedDataSource dataSourceCtor={dataSourceCtor}>
                {props => (
                    <SelectIdTableWithSearch
                        {...props}
                        onSelect={onSelect}
                        tableProps={{
                            dataColumns: [
                                {
                                    title: 'Name',
                                    dataIndex: 'name',
                                },
                            ],
                            extractId
                        }}
                    />
                )}
            </WithManagedDataSource>
        </Modal>
    );
}
