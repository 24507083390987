import {FormContextModal} from "./FormContextModal";
import {message, Spin} from "antd";
import {ComposedExtractedAttribute, DefaultExtractedAttribute} from "../selected-node/api/ExtractedAttribute";
import {AssignClassOnCreation, ExtractedEntityClass} from "../selected-node/api/ExtractedEntityClass";
import {GenericEntityForm} from "../../forms";
import {useEffect, useState} from "react";
import {EhApi} from "../../../services";


function initialMMName(origin) {
    return origin.entity_id + '-PARENT';
}


const extractedAttributes = new ComposedExtractedAttribute([
    new DefaultExtractedAttribute(),
    new ExtractedEntityClass(
        new AssignClassOnCreation('material')
    )
]);


const emptyMaterialAttributes = extractedAttributes.extract({
    entity_id: '',
    description: '',
    entity_class: {
        id: null,
        name: null,
        attributes: []
    }
});


export function CreateAsMMParentModal() {
    const [loading, setLoading] = useState(false);

    return (
        <FormContextModal
            title={subject => `Create MM Parent`}
            expectedType={'create_as_mm_parent'}
            formId={'create-mm-parent-modal'}
            okText={"Create"}
            modalRender={node => (
                <Spin spinning={loading}>
                    {node}
                </Spin>
            )}
        >
            {(subject, close, formId) => (
                <Body subject={subject} close={close} formId={formId} setLoading={setLoading}/>
            )}
        </FormContextModal>
    );
}


function Body({subject, close, formId, setLoading}) {
    const [originMatAttributes, setOriginMatAttributes] = useState(null);

    useEffect(() => {
        let cancelled = false;
        setLoading(true);

        EhApi.get(
            `/materials/${subject.originMatId}`
        ).then(response => {
            if (!cancelled) {
                const data = {...response.data, entity_id: initialMMName(response.data)};
                setOriginMatAttributes(extractedAttributes.extract(data));
            }
        }).catch(_ => {
            if (!cancelled) {
                message.error('Failed to load origin attributes');
                setOriginMatAttributes(emptyMaterialAttributes);
            }
        }).finally(_ => {
            setLoading(false);
        });

        return () => {
            cancelled = true;
        }
    }, []);

    return originMatAttributes && (
        <GenericEntityForm
            id={formId}
            attributes={originMatAttributes}
            onFinish={
                values => {
                    setLoading(true);
                    return subject
                        .handle(values)
                        .then(_ => {
                            message.success('MM Parent created')
                            close();
                        })
                        .catch(() => {message.error('Failed to create MM Parent')})
                        .finally(_ => {setLoading(false)})
                }
            }
        />
    );
}
