import {ContextModal} from "./ContextModal";

export function FormContextModal({formId, children, ...rest}) {
	return (
		<ContextModal
			okButtonProps={{
				type: "primary",
				form: formId,
				key: "submit",
				htmlType: "submit"
			}}
			width={800}
			{...rest}
		>
			{(...args) => children(...args, formId)}
		</ContextModal>
	);
}