import {forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from "react";
import {message, Space, Spin, Tree} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {
	BomNode,
	ConstructionTypeNode,
	ExpandedNodeChild,
	MaterialNode, PmPlanNode
} from "./response-to-tree-data/response-adapters";
import AutoSizer from "react-virtualized-auto-sizer";
import {HierarchyActionsProvider} from "./HierarchyActionsContext";
import {TreeSyncSelectedNode} from "./selected-node/api/ApiSelectedNode";
import {
	AssignBomModal,
	CopyBomModal,
	AssignMaterialModal,
	CreateEquipmentModal,
	CreateFlocModal,
	CreateAsMMParentModal, AssignPmPlanModal
} from "./context-modals";
import {timer} from "rxjs";
import {filter, take, tap} from "rxjs/operators"
import {GroupConstructionTypeAssignModal} from "./context-modals/GroupConstructionTypeAssignModal";
import {BulkReplaceMaterialModal} from "./context-modals/BulkReplaceMaterialModal";
import {BulkUpdateAttributeValueModal} from "./context-modals/BulkUpdateAttributeValueModal";


export function parsedChildren(expandedNode, key, setSelectedNode, node) {
	const nodeChildren = expandedNode.children ?? [];
	const nodeMaterials = expandedNode.materials ?? [];
	const nodeBoms = expandedNode.boms ?? [];
	const nodeConstructionType = expandedNode.construction_type ?? [];
	const nodePmPlans = expandedNode.pm_plans ?? [];

	const children = nodeChildren.map(child => ExpandedNodeChild(child, key, setSelectedNode, node));
	children.push(...nodeMaterials.map(material => MaterialNode(material, key, setSelectedNode, node)));
	children.push(...nodeBoms.map(bom => BomNode(bom, key, setSelectedNode, node)));
	children.push(...nodeConstructionType.map(ct => ConstructionTypeNode(ct, key, setSelectedNode, node)));
	children.push(...nodePmPlans.map(pm => PmPlanNode(pm, key, setSelectedNode, node)));
	return children;
}


export function updateTreeData(list, key, expandedNode, setSelectedNode) {
	return list.map(node => {
		if (node.key === key) {
			const children = parsedChildren(expandedNode, key, setSelectedNode, node);
			node.animated().expandWithTreeItems(children);
			return node;
		}

		node.children = updateTreeData(node.children, key, expandedNode, setSelectedNode);
		return node;
	});
}


export function* hierarchyNodes(data) {
	const nodes = [...data];

	while (nodes.length > 0) {
		const node = nodes.pop();
		const nodeChildren = [...node.children ?? []];
		yield node;
		nodes.push(...nodeChildren);
	}
}


export function* hierarchyNodesWithId(data, id) {
	for (const node of hierarchyNodes(data)) {
		if (node.animated().idIs(id)) yield node;
	}
}


export class RootDataSource {
	async value() {
		return {
			data: [],
			expandedKeys: []
		}
	}
}


export class FallbackRootDataSource extends RootDataSource {
	constructor(origin, callback) {
		super();
		this._origin = origin;
		this._callback = callback;
	}

	async value() {
		return this._origin.value().catch(e => {
			this._callback();
			console.error('Error loading origin RootDataSource ', e);
			return {
				data: [],
				expandedKeys: []
			}
		})
	}
}


export class MeasuredRootDataSource extends RootDataSource {
	constructor(origin, subjectName) {
		super();
		this._origin = origin;
		this._subjectName = subjectName;
	}

	async value() {
		const start = Date.now();
		const result = await this._origin.value();
		console.log(`${this._subjectName} loaded in ${Date.now() - start} msec`);
		return result;
	}
}


export const Hierarchy = forwardRef(({setSelectedNode, rootDataSource, onRootLoaded, emptyDataRender}, ref) => {
	const [rootData, setRootData] = useState([]);
	const [defaultExpandedKeys, setDefaultExpandedKeys] = useState([]);

	useEffect(() => {
		let canceled = false;
		setRootData([]);
		rootDataSource.value().then(data => {
			if (!canceled) {
				setRootData(data.data);
				setDefaultExpandedKeys(data.expandedKeys);
			}
		})
		return () => {
			canceled = true;
		}
	}, [rootDataSource]);

	useLayoutEffect(() => {
		onRootLoaded(rootData);
	}, [rootData]);

	const defaultEmptyDataRender = (
		<Space style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center'}}>
			<Spin spinning={true}/>
		</Space>
	);

	return (
		<Conditional
			cond={_ => rootData.length === 0}
			left={emptyDataRender ?? defaultEmptyDataRender}
			right={(
				<UnsafeHierarchy
					ref={ref}
					rootData={rootData}
					setSelectedNode={setSelectedNode}
					defaultExpandedKeys={defaultExpandedKeys}
				/>
			)}
		/>
	);
})


function Conditional({cond, left, right}) {
	return cond() ? left : right;
}


const UnsafeHierarchy = forwardRef(({setSelectedNode, rootData, defaultExpandedKeys}, ref) => {
	const [treeData, setTreeData] = useState(rootData);
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [expandedKeys, setExpandedKeys] = useState(defaultExpandedKeys);
	const treeRef = useRef();

	const onSelectNode = useCallback((node) => {
		if (selectedKeys.includes(node.key)) return;

		const animatedNode = node.animated();
		setSelectedNode(
			new TreeSyncSelectedNode(
				animatedNode,
				treeData,
				setTreeData,
				setIsLoading,
				ref,
				animatedNode.asSelectedNode()
			)
		);
		setSelectedKeys([node.key]);
	}, [ref, selectedKeys, treeData, setTreeData, setIsLoading, setSelectedNode]);

	useImperativeHandle(ref, () => {
		const ensureNodeIsVisible = (check, nodeKey) => {
			const attempts$ = timer(100, 200).pipe(take(8));

			return attempts$.pipe(
				tap(() => {
					if (treeRef.current) treeRef.current.scrollTo({key: nodeKey, align: 'top'});
				}),
				filter(() => {
					const elements = document.getElementsByClassName('ant-tree-treenode-selected')
					if (elements.length === 0) return false;
					const element = elements[0];
					const spans = element.getElementsByClassName("node-text");
					if (spans.length === 0) return false;
					const span = spans[0]
					return check(span.innerText);
				}),
				take(1),
			);
		}

		const scrollToNode = node => {
			setExpandedKeys(keys => [...new Set([...keys, ...node.animated().parentKeys()])]);
			onSelectNode(node);
			ensureNodeIsVisible(text => node.entityId === text, node.key).subscribe();
		}

		return ({
			scrollTo: (id) => {
				for (const node of hierarchyNodes(treeData)) {
					if (node.id == id) {
						scrollToNode(node);
					}
				}
			},
			scrollToByPath: (path) => {
				path.act(hierarchyNodes(treeData), node => scrollToNode(node));
			}
		});
	}, [onSelectNode, treeData]);

	const loadData = ({key, animated}) => {
		return new Promise((resolve) => {
			animated().expandedInfo()
				.then(data => setTreeData(origin => updateTreeData(origin, key, data, setSelectedNode)))
				.catch(_ => message.error('Error'))
				.then(_ => {
					setTimeout(() => {
						resolve();
					}, 0);
				});
		});
	}

	const onExpand = (expandedKeysValue) => {
		setExpandedKeys(expandedKeysValue);
	}

	return (
		<div style={{height: '100%', width: '100%', overflowX: 'hidden'}}>
			<AutoSizer>
				{({height, width}) => (
					<>
						<div style={{height: `${height}px`, width: `${width}px`, overflow: 'visible'}}>
							<Spin spinning={isLoading}>
								<HierarchyActionsProvider
									treeData={treeData}
									setTreeData={setTreeData}
									setIsLoading={setIsLoading}
								>
									<Modals/>
									<Tree
										showLine={{showLeafIcon: false}}
										ref={treeRef}
										onSelect={(keys, payload) => {
											onSelectNode(payload.node);
										}}
										blockNode
										selectedKeys={selectedKeys}
										switcherIcon={<DownOutlined/>}
										height={height}
										onExpand={onExpand}
										loadedKeys={[]}
										expandedKeys={expandedKeys}
										treeData={treeData}
										loadData={loadData}
										onRightClick={({node}) => {
											if (!node.animated().isDisabled()) {
												onSelectNode(node);
											}
										}}
									/>
								</HierarchyActionsProvider>
							</Spin>
						</div>
					</>
				)}
			</AutoSizer>
		</div>
	);
})

function Modals() {
	return (
		<>
			<CreateAsMMParentModal/>
			<AssignMaterialModal/>
			<AssignBomModal/>
			<CopyBomModal/>
			<AssignPmPlanModal/>
			<CreateFlocModal/>
			<CreateEquipmentModal/>
			<GroupConstructionTypeAssignModal/>
			<BulkReplaceMaterialModal/>
			<BulkUpdateAttributeValueModal/>
		</>
	);
}
