import {Card} from "antd";

export function FullHeightCard({children, ...props}) {
	return (
		<Card
			{...props}
			headStyle={{
				overflow: "auto"
			}}
			style={{
				height: "100%",
				maxHeight: "100%",

				display: 'flex',
				flexDirection: 'column',

				boxShadow: "0px 8px 10px 0px rgba(208, 216, 243, 0.6)",
				...props.style
			}}
			bodyStyle={{
				flexGrow: "1",
				overflow: "auto",
			}}
		>
			{children}
		</Card>
	);
}
