import {GenericEntityFormModal} from "../../modals";
import {message, Modal} from "antd";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {HierarchyGroupOperationsContext} from "../HierarchyGroupOperationsContext";
import {HierarchyActionsContext, withCheckedKey} from "../HierarchyActionsContext";
import {empty} from "../../../utils";
import {ComposedExtractedAttribute, SimpleExtractedAttribute} from "../selected-node/api/ExtractedAttribute";
import {FilterableFormItem, RuleSingleValue} from "../../forms";
import {AdaptedSelectIdDataSource, SelectIdTableWithSearch, WithManagedDataSource} from "../../tables";
import {MaterialsDataSource} from "../../../pages/materials/Materials";
import {ClickToSelect} from "../../Misc";
import {EhApi} from "../../../services";
import {NodeGroup} from "../response-to-tree-data/response-adapters";

const extractedAttributes = new ComposedExtractedAttribute([
    new SimpleExtractedAttribute(
        model => [{
            path: 'source',
            value: model.source,
            toRequestValues: values => ({source_id: values.source.id}),
            toFormItem: () => (
                <FilterableFormItem
                    required
                    name={["source"]}
                    label={"Source Material"}
                    key={"source"}
                    rules={[
                        RuleSingleValue(
                            value => value.id != null,
                            'Source Material is required'
                        )
                    ]}
                >
                    <SelectMaterial/>
                </FilterableFormItem>
            )
        }]
    ),
    new SimpleExtractedAttribute(
        model => [{
            path: 'target',
            value: model.target,
            toRequestValues: values => ({target_id: values.target.id}),
            toFormItem: () => (
                <FilterableFormItem
                    required
                    name={["target"]}
                    label={"Target Material"}
                    key={"target"}
                    rules={[
                        RuleSingleValue(
                            value => value.id != null,
                            'Target Material is required'
                        )
                    ]}
                >
                    <SelectMaterial/>
                </FilterableFormItem>
            )
        }]
    )
]);

const emptyAssignAttributes = extractedAttributes.extract({
    source: {
        id: null,
        name: null
    },
    target: {
        id: null,
        name: null
    }
});


export function BulkReplaceMaterialModal() {
    const setGroupOperations = useContext(HierarchyGroupOperationsContext);
    const {treeData, setTreeData} = useContext(HierarchyActionsContext);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handle = setGroupOperations ?? empty;
        const key = 'replace-material';

        handle(prev => {
            return [
                ...prev,
                {
                    order: 2,
                    toItem: () => ({
                        data: {
                            key: key,
                            label: 'Replace Material',
                        },
                        onItemClick: withCheckedKey(key, _ => {
                            setVisible(true)
                        })
                    })
                }
            ];
        })
        return () => {
            setGroupOperations(prev => prev.filter(el => el.toItem().data.key !== key));
        }
    }, [setGroupOperations]);

    const handleFinish = values => new Promise((resolve, reject) => {
        const sourceId = values.source_id;
        const targetId = values.target_id;

        EhApi.post(
            `/materials/${sourceId}/replaced`,
            {target_material_id: targetId}
        ).then(response => {
            const replaceTargetPreview = response.data;
            new NodeGroup(treeData, sourceId).replaceWith(replaceTargetPreview);
            setTreeData([...treeData]);
            resolve();
        }).catch(reject);
    });

    return (
        <GenericEntityFormModal
            visible={visible}
            width={612}
            formLayout={{
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
            }}
            updateVisibility={setVisible}
            attributes={emptyAssignAttributes}
            onFinish={handleFinish}
            handleError={(e) => {
                message.error("Failed to replace");
            }}
            title={'Replace Material'}
            okText={'Replace'}
        />
    );
}


function SelectMaterial({onChange, value}) {
    const [modalVisible, setModalVisible] = useState(false);
    const dataSourceCtor = useCallback((q) => {
        return new AdaptedSelectIdDataSource(
            new MaterialsDataSource(q)
        );
    }, []);

    const handleChange = v => {
        onChange({
            name: v?.entity_id,
            id: v?.id
        });
    }

    const handleSelect = v => {
        handleChange(v);
        closeModal();
    }

    const showModal = _ => {
        setModalVisible(true);
    }

    const closeModal = _ => {
        setModalVisible(false);
    }

    return (
        <>
            <Modal
                visible={modalVisible}
                onCancel={closeModal}
                title={"Select Class"}
                width={'50vw'}
                footer={null}
                destroyOnClose
            >
                <WithManagedDataSource dataSourceCtor={dataSourceCtor}>
                    {props => (
                        <SelectIdTableWithSearch
                            {...props}
                            onSelect={handleSelect}
                            tableProps={{
                                extractId: r => r
                            }}
                        />
                    )}
                </WithManagedDataSource>
            </Modal>
            <ClickToSelect
                value={value?.name}
                assignProps={{
                    title: 'Assign Source Material',
                    onClick: showModal
                }}
                clearProps={{
                    title: 'Clear Source Material',
                    onClick: _ => {
                        handleChange(null)
                    }
                }}
            />
        </>
    );
}