import Descriptions from "antd/es/descriptions";
import {message, Table, Tabs} from "antd";
import {TypeTag} from "../../TypeTag";
import {useHistory} from "react-router-dom";
import {EHLink} from "../../../pages/search/Search";
import {createContext, useEffect, useMemo, useState} from "react";
import {EditFilled} from "@ant-design/icons";
import {GenericEntityFormModal} from "../../modals";
import {SmartAttributes} from "../selected-node/SmartAttributes";
import {AsyncDataSource, ManualPagedTable, OperationLogsDataSource, OperationLogsTable} from "../../tables";
import {EntityLinksDropdown} from "../../dropdowns";


export const NodeInfoContext = createContext({
	classAttrFilter: {
		apply: attrs => attrs,
		setHideEmptyValues: value => {},
		hideEmptyValues: true
	}
});


function NodeInfoContextProvider({children}) {
	const [hideEmptyValues, setHideEmptyValues] = useState(true);

	const classAttrFilter = useMemo(() => ({
		apply: attrs => {
			let result;
			if (hideEmptyValues) {
				result = attrs.filter(el => el.value);
			} else {
				result = attrs;
			}
			return result;
		},
		setHideEmptyValues,
		hideEmptyValues
	}), [hideEmptyValues]);

	return (
		<NodeInfoContext.Provider value={{classAttrFilter}}>
			{children}
		</NodeInfoContext.Provider>
	)
}


export function NodeInfo({links, parents, pmPlans, onEdit, attributes, logs}) {
	const [editModalVisible, setEditModalVisible] = useState(false);
	const smartAttributes = new SmartAttributes(attributes);

	return (
		<div>
			<GenericEntityFormModal
				visible={editModalVisible}
				updateVisibility={setEditModalVisible}
				attributes={attributes}
				onFinish={onEdit}
				handleError={() => {message.error("Failed to save changes")}}
				title={'Edit'}
				okText={"Save changes"}
			/>
			<NodeInfoContextProvider>
				<Descriptions
					title={(
						<span>
						Node Info
						<EditButton attributes={attributes} onClick={_ => setEditModalVisible(true)}/>
						<EntityLinksDropdown source={links}/>
					</span>
					)}
					column={1}
				>
					{smartAttributes.toDescriptionItems()}
				</Descriptions>
				<Tabs>
					{parents && parents.length > 0 && (
						<Tabs.TabPane tab={`Parents (${parents.length})`} key="parents">
							<ParentsTable parents={parents}/>
						</Tabs.TabPane>
					)}
					{!pmPlans.hidden && (
						<Tabs.TabPane tab={`PM Plans`} key="pm-plans">
							<PmPlansTable pmPlans={pmPlans}/>
						</Tabs.TabPane>
					)}
					{!logs.hidden && (
						<Tabs.TabPane tab={`Logs`} key="entity-logs">
							<NodeLogsTable logs={logs}/>
						</Tabs.TabPane>
					)}
					{smartAttributes.toTabs()}
				</Tabs>
			</NodeInfoContextProvider>
		</div>
	);
}


function EditButton({attributes, onClick}) {
	return attributes.filter(el => !el.readonly).length > 0 && (
		<a id="edit-entity-button" style={{marginLeft: '8px'}} onClick={onClick}><EditFilled/></a>
	)
}


function ParentsTable({parents}) {
	const history = useHistory()

	const columns = [
		{
			title: 'Type',
			dataIndex: 'type',
			render: _type => (
				<TypeTag _type={_type}/>
			)
		},
		{
			title: 'Item',
			dataIndex: 'entity_id',
			render: (entity_id, item) =>
				<EHLink type={item.type} title={entity_id} entity={item} history={history}/>
		},
		{
			title: 'Description',
			dataIndex: 'description',
		},
		{
			title: 'Actions',
			dataIndex: 'id',
			render: (id, item) =>
				<EHLink type={item.type} title={'Open'} entity={item} history={history}/>
		}
	]

	return (
		<Table
			id="node-parents-table"
			size="small"
			columns={columns}
			dataSource={parents}
			rowKey={record => record.id}
		/>
	);
}


function PmPlansTable({pmPlans}) {
	const [dataSource, setDataSource] = useState(new AsyncDataSource());
	const history = useHistory();

	useEffect(() => {
		setDataSource(pmPlans.dataSource);
	}, [pmPlans]);

	const columns = [
		{
			title: 'Description',
			dataIndex: 'description',
		},
		{
			title: 'Maint Item Text',
			dataIndex: 'maintText'
		},
		{
			title: 'Actions',
			dataIndex: 'id',
			render: (id) =>
				<EHLink type={'pm_plan'} title={'Open'} entity={{id: id}} history={history}/>
		}
	]

	return (
		<ManualPagedTable
			asyncDataSource={dataSource}
			columns={columns}
			rowKey={record => record.id}
			size={"small"}
		/>
	);
}


function filterColumns(columns) {
	return columns.filter(x => ["timestamp", "user", "operation", "details", "revision"].includes(x.dataIndex));
}

function NodeLogsTable({logs}) {
	const [dataSource, setDataSource] = useState(new AsyncDataSource());

	useEffect(() => {
		setDataSource(new OperationLogsDataSource("", logs.objectId));
	}, [logs]);

	return (
		<OperationLogsTable
			dataSource={dataSource}
			filterColumns={filterColumns}
		/>
	);
}
