import React from "react";

export class ExtractedAttribute {
	extract(model) {
		return [];
	}
}


export class SimpleExtractedAttribute extends ExtractedAttribute {
	constructor(handle) {
		super();
		this._handle = handle;
	}

	extract(model) {
		return this._handle(model);
	}
}


export class PlainExtractedAttribute extends SimpleExtractedAttribute {
	constructor(key, rest=key=>({})) {
		super(model => [{
			path: key,
			value: model[key],
			toRequestValues: values => ({[key]: values[key]}),
			...rest(key),
		}]);
	}
}


export class ComposedExtractedAttribute extends ExtractedAttribute {
	constructor(origins) {
		super();
		this._origins = origins;
	}

	extract(model) {
		return this._origins.flatMap(o => o.extract(model));
	}
}


class ExtractedDescription extends ExtractedAttribute {
	extract(model) {
		return [{
			path: 'description',
			label: 'Description',
			value: model.description,
			readonly: false,
			type: 'text',
			actualizeTreeItemFields: i => {
				i.description = model.description
			},
		}];
	}
}



export class ExtractedTagNumber extends ExtractedAttribute {
	extract(model) {
		return [{
			path: 'tag_number',
			label: 'Tag Number',
			value: model.tag_number,
			readonly: false,
			type: 'text'
		}];
	}
}



export class ExtractedQuantity extends ExtractedAttribute {
	constructor(parentId) {
		super();
		this._parentId = parentId;
	}

	extract(model) {
		return [{
			path: 'quantity',
			label: 'Quantity',
			value: model.quantity,
			readonly: false,
			type: 'non_negative_int',
			actualizeTreeItemFields: i => {
				if (i.animated().parentIdIs(this._parentId)) {
					i.quantity = model.quantity;
				}
			},
		}];
	}
}


class ExtractedEntityId extends ExtractedAttribute {
	extract(model) {
		return [{
			path: 'entity_id',
			label: 'Item',
			value: model.entity_id,
			actualizeTreeItemFields: i => {
				i.entityId = model.entity_id
			},
			readonly: false,
			type: 'text',
			rules: [
				{
					required: true,
					message: 'Item is required'
				}
			]
		}];
	}
}


class SkipParsingIfFieldIsNull extends ExtractedAttribute {
	constructor(origin, fieldToCheck) {
		super();
		this._origin = origin;
		this._fieldToCheck = fieldToCheck;
	}

	extract(model) {
		if (model[this._fieldToCheck] == null) return [];
		return this._origin.extract(model);
	}
}


export class DefaultExtractedAttribute extends ComposedExtractedAttribute {
	constructor() {
		super([
			new ExtractedEntityId(),
			new ExtractedDescription()
		]);
	}
}
