import React from "react";
import {Table} from "antd";
import {ColoredColumns} from "../colored-columns/ColoredColumns";

const defaultColors = {
    'change': '#e6f7ff',
    'add': '#f6ffed',
    'remove': '#fff1f0'
}

const defaultChangeNaming = {
    'change': 'Update',
    'add': 'Create',
    'remove': 'Delete'
}

const defaultAttrNaming = {
    'class_attributes': 'Class Attributes',
    'class_name': 'Class',
    'description': 'Description',
    'entity_id': 'Item',
    'tag_number': 'Tag Number',
    'quantity': 'Quantity',

    apply: function (origin) {
        const key = origin.split(".")[0];
        const rest = origin.split(".")[1] ?? '';
        if (this[key]) {
            const head = this[key];
            if (rest) {
                return `${rest} (${head})`;
            } else {
                return head;
            }
        } else {
            return origin;
        }
    }
}

export function DiffTable({
                              diff,
                              colors = defaultColors,
                              changeNaming = defaultChangeNaming,
                              attrNaming = defaultAttrNaming
                          }) {
    const data = (diff ?? []).map((x, idx) => {
        return {
            id: idx,
            change: x[0],
            attribute: x[1],
            payload: x[2]
        };
    });
    const columns = [
        {
            title: 'Operation',
            dataIndex: 'change',
            render: v => changeNaming[v]
        },
        {
            title: 'Attribute',
            dataIndex: 'attribute',
            render: v => attrNaming.apply(v.toString())
        },
        {
            title: 'Details',
            dataIndex: 'payload',
            render: (payload, record) => {
                console.log(payload);
                if (record.change === 'change') {
                    const from = payload[0] ?? '(empty)';
                    const to = payload[1] ?? '(empty)';
                    return (
                        <span>
                            from <strong>{from}</strong> to <strong>{to}</strong>
                        </span>
                    );
                } else if (['add', 'remove'].includes(record.change)) {
                    const key = payload[0][0] ?? '(empty)';
                    const value = payload[0][1] ?? '(empty)';
                    return (
                        <span>
                            <strong>{key}</strong> with value <strong>{value}</strong>
                        </span>
                    );
                }
            }
        },
    ];
    const coloredColumns = new ColoredColumns(
        columns,
        record => colors[record.change]
    );
    return (
        <>
            <Table
                size={"small"}
                columns={coloredColumns.values()}
                dataSource={data}
                rowKey={x => x.id}
            />
        </>
    );
}