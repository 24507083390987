import {Checkbox, Collapse, Descriptions, Form, Input, Popover, Space, Table, Tabs, Tooltip} from "antd";
import {Fragment, useContext, useEffect, useState} from "react";
import {EhApi} from "../../../../services";
import {FilterableFormItem, FormItemLabel, FormLoadingContext} from "../../../forms";
import {ExtractedAttribute} from "./ExtractedAttribute";
import {FilterFormItemsContext} from "../../../forms/generic-entity-form/GenericEntityForm";
import {EntityClassSelect} from "../../../forms/data-items";
import {SettingsButton} from "../../../buttons";
import {NodeInfoContext} from "../../node-info/NodeInfo";


class EntityClassRenderStrategy {
    toFormItem() {
        return <></>;
    }

    toRequestValues(values) {
        return {};
    }
}


export class ExtractedEntityClass extends ExtractedAttribute {
    constructor(classRenderStrategy) {
        super();
        this._classRenderStrategy = classRenderStrategy;
    }

    extract(model) {
        return [{
            path: 'entity_class',
            label: 'Class',
            value: model.entity_class,
            readonly: false,
            toRequestValues: values => ({
                'class_attributes': values.entity_class.attributes.map(a => ({id: a.id, value: a.value})),
                ...this._classRenderStrategy.toRequestValues(values)
            }),
            toDescriptionsItem: () => (
                <Fragment key={"class-items"}>
                    <Descriptions.Item label={"Class"} key={"Class"}>
                        {model.entity_class.name ?? '(empty)'}
                    </Descriptions.Item>
                </Fragment>
            ),
            toFormItem: () => {
                return (
                    <Fragment key={"class-items"}>
                        {this._classRenderStrategy.toFormItem()}
                        <Collapse>
                            <Collapse.Panel header={"Attributes"} key={"attributes"} forceRender>
                                <AttributeFormList/>
                            </Collapse.Panel>
                        </Collapse>
                    </Fragment>
                );
            },
            toTab: () => {
                if (model.entity_class.name != null) {
                    const attrs = model.entity_class.attributes;
                    return [(
                        <Tabs.TabPane
                            tab={(
                                <>
                                    <AttributesTabTitle attributes={attrs}/>
                                    <AttributesTabSettings/>
                                </>
                            )}
                            key="class-attributes"
                        >
                            <AttributesTabContent attributes={attrs}/>
                        </Tabs.TabPane>
                    )];
                } else {
                    return [];
                }
            }
        }];
    }
}


function AttributesTabTitle({attributes}) {
    return (
        <FilteredAttributes attributes={attributes}>
            {({filtered}) => {
                const filteredLength = filtered.length;
                const allLength = attributes.length;
                let msg;
                if (filteredLength === allLength) {
                    msg = `Class Attributes (${filtered.length})`;
                } else {
                    msg = `Class Attributes (${filtered.length} of ${attributes.length})`;
                }
                return (
                    <span>{msg}</span>
                );
            }}
        </FilteredAttributes>
    );
}


function AttributesTabSettings({...rest}) {
    const trigger = (
        <SettingsButton style={{marginLeft: '8px', marginTop: '4px'}}/>
    );

    return (
        <Popover
            {...rest}
            trigger={["click"]}
            title={"Settings"}
            content={<AttributesTabSettingsContent/>}
            placement={"bottom"}
        >
            <Tooltip title={"Settings"}>
                {trigger}
            </Tooltip>
        </Popover>
    );
}


function AttributesTabSettingsContent() {
    const nodeInfoContext = useContext(NodeInfoContext);

    return (
        <div style={{width: "188px"}}>
            <Space direction={"vertical"}>
                <Checkbox
                    checked={!nodeInfoContext.classAttrFilter.hideEmptyValues}
                    onChange={e => {
                        nodeInfoContext.classAttrFilter.setHideEmptyValues(!e.target.checked);
                    }}
                >
                    Show Empty Attributes
                </Checkbox>
            </Space>
        </div>
    );
}


function FilteredAttributes({attributes, children}) {
    const nodeInfoContext = useContext(NodeInfoContext);
    return children({filtered: nodeInfoContext.classAttrFilter.apply(attributes)});
}


function AttributesTabContent({attributes}) {
    return (
        <FilteredAttributes attributes={attributes}>
            {({filtered}) => (
                <AttributesTabTable attributes={filtered}/>
            )}
        </FilteredAttributes>
    );
}


function AttributesTabTable({attributes}) {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Value',
            dataIndex: 'value',
        }
    ]

    return (
        <Table
            id="class-attributes"
            size="small"
            columns={columns}
            dataSource={attributes}
            rowKey={record => record.id}
        />
    );
}


function AttributeFormList() {
    const form = Form.useFormInstance();
    const filterContext = useContext(FilterFormItemsContext);

    const isHidden = (field) => {
        return !filterContext.test({
            label: () => {
                const fieldLabel = form.getFieldValue(["entity_class", "attributes", field.name, "name"]);
                return fieldLabel;
            }
        });
    }

    return (
        <Form.List name={["entity_class", "attributes"]}>
            {fields => fields.map(field => {
                return (
                    <Fragment key={field.key}>
                        <Form.Item
                            hidden={isHidden(field)}
                            name={[field.name, 'value']}
                            label={<FormItemLabel name={[field.name, 'name']}/>}
                        >
                            <Input/>
                        </Form.Item>
                    </Fragment>
                );
            })}
        </Form.List>
    );
}


export class AssignClassOnCreation extends EntityClassRenderStrategy {
    constructor(classType, formProps={}) {
        super();
        this._classType = classType;
        this._formProps = formProps;
    }

    toFormItem() {
        return (
            <FilterableFormItem {...this._formProps} name={["entity_class", "id"]} label={"Class"}>
                <ClassSelect classType={this._classType}/>
            </FilterableFormItem>
        );
    }

    toRequestValues(values) {
        return {
            'class_id': values.entity_class.id
        };
    }
}


export class AssignClassOnEdit extends EntityClassRenderStrategy {
    toFormItem() {
        return <></>;
    }

    toRequestValues(values) {
        return {};
    }
}


export async function classAttributes(classId) {
    if (classId == null) {
        return [];
    }
    return new Promise((resolve, reject) => {
        const mapResponse = r => r.data.sort((a, b) => a.sort_order - b.sort_order).map(el => ({
            id: el.id,
            name: el.name,
            value: null
        }));

        EhApi.get(`/class-library/${classId}/attributes`)
            .then(mapResponse)
            .then(resolve)
            .catch(reject);
    });
}

async function className(classId) {
    if (classId == null) {
        return null;
    }
    return new Promise((resolve, reject) => {
        EhApi.get(`/class-library/classes/${classId}`)
            .then(response => response.data.name)
            .then(resolve)
            .catch(reject);
    });
}


function mergeAttributes(form, originData, mergeHeader) {
    const attrValues = form.getFieldValue(["entity_class", "attributes"]).reduce(
        (map, attr) => {
            const updated = {...map};
            updated[attr.name] = attr.value;
            return updated;
        },
        {}
    );

    const attrs = originData.map(el => ({
        ...el,
        value: attrValues[el.name] ?? null
    }));

    form.setFieldsValue({
        entity_class: {
            ...mergeHeader,
            attributes: attrs
        }
    });
}


function ClassSelect({classType, ...rest}) {
    const form = Form.useFormInstance();
    const {setLoading: setFormLoading} = useContext(FormLoadingContext);
    const [name, setName] = useState(null);

    useEffect(() => {
        Promise.all([
            className(rest.value).then(setName),
            classAttributes(rest.value).then(
                data => {
                    mergeAttributes(form, data, {id: rest.value});
                }
            )
        ]).finally(_ => {
            setFormLoading(false);
        });
    }, []);


    const handleChange = (selectedClass) => {
        if (selectedClass == null) {
            form.setFieldsValue({
                entity_class: {
                    id: null,
                    attributes: []
                }
            })
            setName(null);
        } else {
            setFormLoading(true);
            setName(selectedClass.name);
            classAttributes(selectedClass.id).then(
                data => {
                    mergeAttributes(form, data, {id: selectedClass.id});
                    rest.onChange(selectedClass.id);
                }
            ).finally(_ => {
                setFormLoading(false);
            });
        }
    }

    return (
        <EntityClassSelect
            classType={classType}
            extractId={r => r}
            value={name}
            onChange={handleChange}
        />
    );
}
