import React, {useState} from "react";
import {Modal, Space, Tag} from "antd";
import {AsyncDataSource, ManualPagedTable} from "../async-data-source-table/AsyncDataSourceTable";
import {useHistory} from "react-router-dom";
import {EHLink} from "../../../pages/search/Search";
import {EhApi} from "../../../services";
import {LinkTextButton} from "../../buttons";
import {DiffTable} from "../diff-table/DiffTable";


export class OperationLogsDataSource extends AsyncDataSource {
    constructor(query, objectId) {
        super();
        this._query = query;
        this._objectId = objectId;
    }

    async values(page, limit) {
        return new Promise((resolve, reject) => {
            EhApi.get(
                '/operation-logs',
                {params: {page, limit, q: this._query, object_id: this._objectId}}
            ).then(response => {
                resolve(response.data);
            }).catch(reject);
        });
    }
}


export function OperationLogsTable({dataSource, filterColumns=c => c}) {
    const [diffToShow, setDiffToShow] = useState(null);

    const showDiff = diff => {
        setDiffToShow(diff);
    }

    const closeDiff = () => {
        setDiffToShow(null);
    }

    const columns = [
        {
            title: 'Request UUID',
            dataIndex: 'request_uuid',
        },
        {
            title: 'Date',
            dataIndex: 'timestamp',
        },
        {
            title: 'User',
            dataIndex: 'user',
        },
        {
            title: 'Endpoint',
            dataIndex: 'endpoint',
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
        },
        {
            title: 'Object',
            dataIndex: 'object',
        },
        {
            title: 'Revision',
            dataIndex: 'revision',
            render: (rev) => <Tag>{rev?.name ?? "(empty)"}</Tag>
        },
        {
            title: 'Details',
            dataIndex: 'details',
            render: (details, item) => {
                const diff = details.diff ?? [];
                const msg = messages?.[item.object]?.[item.operation];
                return (
                    <Space>
                        <div>{msg ? msg(details) : JSON.stringify(details)}</div>
                        {diff.length > 0 && <LinkTextButton text={"Diff"} onClick={_ => showDiff(diff)}/>}
                    </Space>
                );
            }
        }
    ];

    return (
        <>
            <Modal
                title={"Diff"}
                closable
                destroyOnClose
                onCancel={closeDiff}
                footer={[]}
                visible={diffToShow != null}
            >
                <DiffTable diff={diffToShow}/>
            </Modal>
            <ManualPagedTable
                asyncDataSource={dataSource}
                columns={filterColumns(columns)}
                rowKey={r => r.id}
                size={"small"}
            />
        </>
    );
}


function Link({entity}) {
    const history = useHistory();
    if (!entity) {
        return 'entity';
    }
    if (entity.entity_type) {
        if (entity.entity_id) {
            return (<>{entity.entity_type} <EHLink type={entity.entity_type} title={entity.entity_id} entity={entity} history={history}/></>)
        } else if (entity.id) {
            return (<><EHLink type={entity.entity_type} title={entity.entity_type} entity={entity} history={history}/></>)
        } else {
            return `${entity.entity_type}(s)`;
        }
    } else {
        if (entity.id) {
            return `entity(id=${entity.id})`;
        } else if (entity.class_id) {
            return `entities(class_id=${entity.class_id})`;
        } else {
            return 'entity';
        }
    }
}


const messages = {
    'entity': {
        'create': details => (<>Created <Link entity={details.entity}/></>),
        'update': details => (<>Updated <Link entity={details.entity}/></>),
        'copy': details => (<>Copied <Link entity={details.original}/> as <Link entity={details.entity}/></>)
    },
    'relation': {
        'create': details => (<>Assigned <Link entity={details.child}/> to parent <Link entity={details.parent}/></>),
        'update': details => (<>Updated relation from <Link entity={details.child}/> to parent <Link entity={details.parent}/> </>),
        'delete': details => (<>Unassigned <Link entity={details.child}/> from parent <Link entity={details.parent}/></>),
        'copy': details => (<>Assigned child nodes of <Link entity={details.original}/> to <Link entity={details.parent}/></>),
        'move': details => (<>Assigned <Link entity={details.child}/> instead of <Link entity={details.original}/> to its parents</>)
    },
    'hierarchy': {
        'create': details => (<>Imported hierarchy from file {details.file}</>)
    }
}
