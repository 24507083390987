import {DefaultFilters} from "./Filters";
import {FilterSchemaEntityItem} from "./eh/entity-attribute/filters/entity-item";
import {FilterSchemaEntityType} from "./eh/entity-attribute/filters/entity-type";
import {FilterSchemaEntityDescription} from "./eh/entity-attribute/filters/entity-description";
import {FilterSchemaEntityClass} from "./eh/entity-attribute/filters/entity-class";
import {FilterSchemaEntityTagNumber} from "./eh/entity-attribute/filters/entity-tag-number";
import {EntityAttributeFilterEditor} from "./eh/entity-attribute/EntityAttributeFilterEditor";
import {ClassAttributeFilterEditor} from "./eh/class-attribute/ClassAttributeFilterEditor";


export function SearchFilters({filters, onFiltersChanged}) {
    return (
        <DefaultFilters
            filters={filters}
            onFiltersChanged={onFiltersChanged}
            filterSchemas={[
                FilterSchemaEntityItem(),
                FilterSchemaEntityType(),
                FilterSchemaEntityDescription(),
                FilterSchemaEntityClass(),
                FilterSchemaEntityTagNumber(),
            ]}
            editors={[
                {
                    type: 'entity_attributes',
                    tabName: 'Attribute',
                    render: (schemasByType, onEditedFilterChanged) => (
                        <EntityAttributeFilterEditor
                            schemas={schemasByType}
                            onEditedFilterChanged={onEditedFilterChanged}
                        />
                    )
                },
                {
                    type: 'entity_class_attributes',
                    tabName: 'Class Attribute',
                    render: (_, onEditedFilterChanged) => (
                        <ClassAttributeFilterEditor onEditedFilterChanged={onEditedFilterChanged}/>
                    )
                }
            ]}
        />
    );
}
