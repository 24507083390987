import {useEffect, useState} from "react";
import {Button, Dropdown, Form, Menu, message, Popover, Row, Space, Spin, Tag} from "antd";
import {Abbreviation} from "../data-renders";
import {RuleSingleValue} from "../forms";
import Input from "antd/es/input/Input";
import {EhApi} from "../../services";

const revisionKey = "revision";
const initialValues = {name: '', description: ''};


export function revisionHeaders() {
    const record = JSON.parse(localStorage.getItem(revisionKey));
    if (record?.id == null) {
        return {};
    } else {
        return {Revision: record.id};
    }
}


function modelToStorage(model) {
    return {
        id: model.id,
        name: model.name,
        description: model.description
    }
}


function resetRevision() {
    localStorage.removeItem(revisionKey);
    window.dispatchEvent(new Event('storage'));
}


function setRevision(data) {
    localStorage.setItem(
        revisionKey,
        JSON.stringify(data)
    );
    window.dispatchEvent(new Event('storage'));
}


export function Revision() {
    const [currentRevision, setCurrentRevision] = useState(localStorage.getItem(revisionKey));

    useEffect(() => {
        const onStorage = () => {
            setCurrentRevision(localStorage.getItem(revisionKey));
        };

        window.addEventListener('storage', onStorage);

        return () => {
            window.removeEventListener('storage', onStorage);
        };
    }, []);

    useEffect(() => {
        EhApi.get(
            '/revisions/active',
        ).then(response => {
            const data = response.data;
            if (data.length > 0) {
                setRevision(modelToStorage(data[0]));
            } else {
                resetRevision();
            }
            window.dispatchEvent(new Event('storage'));
        });
    }, []);

    const endCurrentRevision = () => {
        const id = JSON.parse(currentRevision)?.id;
        if (!id) {
            window.location.reload();
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            EhApi.patch(
                `/revisions/${id}`,
                {status: 'completed'}
            ).then(_ => {
                resetRevision();
                message.success("Ended");
            }).catch(reject);
        });
    }

    const createRevision = values => {
        return new Promise((resolve, reject) => {
            EhApi.post(
                '/revisions',
                {...values}
            ).then(response => {
                setRevision(modelToStorage(response.data));
                window.dispatchEvent(new Event('storage'));
                message.success("Started");
            }).catch(reject);
        });
    }

    const started = (
        <StartedRevision
            revision={JSON.parse(currentRevision)}
            endCurrentRevision={endCurrentRevision}
        />
    );

    const empty = (
        <EmptyRevision onCreateNew={createRevision} initialValues={initialValues}/>
    );

    return currentRevision ? started : empty;
}


function StartedRevision({revision, endCurrentRevision}) {
    const name = revision?.name ?? "(empty)";
    const description = revision?.description ?? "(empty)";

    const overlay = (
        <Menu>
            <Menu.Item key="end-current-revision" onClick={endCurrentRevision}>
                End
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={overlay} trigger="click">
            <RevisionTag color={'cyan'}>
                Rev.:{" "}
                <Abbreviation
                    value={{
                        toString: () => `${name}`,
                        render: x => x,
                        tooltipTitle: () => `${name}: ${description}`
                    }}
                    tooltipProps={{placement: "right"}}
                />
            </RevisionTag>
        </Dropdown>
    );
}


function EmptyRevision({onCreateNew, initialValues}) {
    const formId = "create-revision-form";
    const [loading, setLoading] = useState(false);
    const [contentVisible, setContentVisible] = useState(false);

    const cancel = () => {
        setContentVisible(false);
    }

    const finish = async values => {
        setLoading(true);
        onCreateNew(values)
            .then(_ => {setContentVisible(false)})
            .finally(_ => {setLoading(false)});
    }

    const footer = (
        <Row>
            <Space style={{marginLeft: 'auto'}}>
                <Button
                    id="cancel-create-revision"
                    size="small"
                    onClick={cancel}
                >
                    Cancel
                </Button>
                <Button
                    form={formId}
                    key={"submit"}
                    htmlType={"submit"}
                    type="primary"
                    size="small"
                    id="accept-create-revision"
                >
                    Start
                </Button>
            </Space>
        </Row>
    );

    const content = (
        <div style={{width: "288px"}}>
            <Spin spinning={loading}>
                <Form
                    id={formId}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    onFinish={finish}
                    initialValues={initialValues}
                >
                    <Form.Item
                        name={"name"}
                        label={"Name"}
                        rules={[
                            RuleSingleValue(
                                value => value,
                                'Name is required'
                            )
                        ]}
                    >
                        <Input size={"small"}/>
                    </Form.Item>
                    <Form.Item
                        name={"description"}
                        label={"Description"}
                    >
                        <Input size={"small"}/>
                    </Form.Item>
                    {footer}
                </Form>
            </Spin>
        </div>
    );

    return (
        <Popover
            visible={contentVisible}
            destroyTooltipOnHide={true}
            trigger="click"
            title="Create Revision"
            placement="bottomRight"
            content={content}
            onVisibleChange={setContentVisible}
        >
            <RevisionTag color={"red"}>
                Start New Revision
            </RevisionTag>
        </Popover>
    );
}


function RevisionTag({children, color, ...rest}) {
    return (
        <Tag style={{marginTop: '4px', marginRight: 0, cursor: "pointer"}} color={color} size="small" {...rest}>
            {children}
        </Tag>
    );
}
