import {InputNumber} from "antd";

export function NonNegativeIntInput({value, onChange, ...rest}) {
    const handleChange = changed => {
        if (Number.isInteger(changed) && changed >= 0) {
            onChange(changed);
        }
    }

    return (
        <InputNumber value={value} onChange={handleChange} min={0} step={1} {...rest}/>
    );
}
