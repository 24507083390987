import axios from "axios";
import {API_URL} from "../../constants";
import {Auth} from "@digatex/digatex-ui-lib"

class EhApi {
	get(uri, config = {}) {
		return axios.get(
			API_URL + uri,
			this._withHeaders(config)
		);
	}

	post(uri, data = {}, config = {}) {
		return axios.post(
			API_URL + uri,
			data,
			this._withHeaders(config)
		);
	}

	put(uri, data = {}, config = {}) {
		return axios.put(
			API_URL + uri,
			data,
			this._withHeaders(config)
		);
	}

	patch(uri, data = {}, config = {}) {
		return axios.patch(
			API_URL + uri,
			data,
			this._withHeaders(config)
		);
	}

	delete(uri, config={}) {
		return axios.delete(
			API_URL + uri,
			this._withHeaders(config)
		);
	}

	_withHeaders(config) {
		return {
			headers: Auth.headers(),
			...config
		}
	}
}

export default new EhApi();