import {filterOperationsMap} from "../../entity-attribute/EntityAttributeFilterEditor";
import {orDelimiter} from "../../../parsers/from-url";
import {DefaultTagSource} from "../../../tag-filters-list/TagFiltersList";

export function FilterClassAttribute(name, values, operation) {
    const type = 'class_attr';
    const asTagValue = (origin) => {
        let result = '';
        if (operation === filterOperationsMap.equal) {
            result = origin;
        } else if (operation === filterOperationsMap.contains) {
            result = `*${origin}*`;
        }
        return result;
    }

    return {
        type, name,
        asRequest: () => (
            {type: type, args: [name, ...values.filter(x => x)], operation}
        ),
        asUrlParams: () => (
            {[`${type}[${name}][${operation}]`]: values.join(orDelimiter)}
        ),
        asTagSource: () => DefaultTagSource(
            name,
            asTagValue(values.join(" OR ")),
            'green'
        ),
        addTo: function (filters) {
            filters = filters.filter(x => {
                const sameType = x.type === type;
                const sameName = x.name === name;
                return !(sameName && sameType);
            });
            return [...filters, this];
        },
        removeFrom: function (filters) {
            return filters.filter(f => f !== this);
        }
    }
}
