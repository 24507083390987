import {REACT_APP_DM_LINK, REACT_APP_IH_LINK, REACT_APP_KH_LINK, REACT_APP_WB_LINK} from "../../../../constants";

export class EntityLinksSource {
    urlByType(linkType) {
        return '';
    }

    filter(items) {
        return [];
    }
}


export class BaseNodeLinks extends EntityLinksSource {
    constructor(links) {
        super();
        this._links = links;
    }

    urlByType(linkType) {
        const link = this._links.find(x => x.type() === linkType) ?? {
            url: () => {
                throw new Error('Unsupported type');
            }
        };
        return link.url();
    }

    filter(items) {
        const supportedTypes = this._links.map(x => x.type());
        return items.filter(x => supportedTypes.includes(x.key));
    }
}


export class SimpleNodeLink {
    constructor(type, url) {
        this._type = type;
        this._url = url;
    }

    url() {
        return this._url;
    }

    type() {
        return this._type;
    }
}


export class EHLink extends SimpleNodeLink {
    constructor(item, type) {
        super('eh', `${window.location.origin}/entity_links?type=${type}&item=${item}`);
    }
}


export class WBLink extends SimpleNodeLink {
    constructor(material) {
        super('wb', `${REACT_APP_WB_LINK}/projects/6295c7173ae8947d89af2c73/min-max-material-analysis?material=${material}`);
    }
}


export class IHLink extends SimpleNodeLink {
    constructor(material) {
        super('ih', `${REACT_APP_IH_LINK}/data?operator=Client&material=${material}`);
    }
}


export class DMLink extends SimpleNodeLink {
    constructor(q) {
        super('dm', `${REACT_APP_DM_LINK}/dm?search=${q}&batch=material-mapped-batch`);
    }
}


export class KHSearchLink extends SimpleNodeLink {
    constructor(q) {
        super('kh', `${REACT_APP_KH_LINK}&q=${q}`);
    }
}
