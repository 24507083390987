export class ColoredColumns {
    constructor(columns, color) {
        this._columns = columns;

        if (typeof color === 'function') {
            this._color = color;
        } else {
            this._color = () => color;
        }
    }

    values() {
        return this._columns.map(col => {
            return {
                ...col,
                onCell: (record, rowIndex) => {
                    let origin = {};
                    if (col.onCell) {
                        origin = {...col.onCell(record, rowIndex)};
                    }
                    return {
                        ...origin,
                        style: {
                            background: this._color(record, rowIndex),
                            ...origin?.style
                        },
                    }
                }
            }
        });
    }
}
