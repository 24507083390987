import {OperationLogsDataSource, OperationLogsTable} from "../../components/tables";
import React, {useMemo, useState} from "react";
import {Input} from "antd";

export function OperationLogsTab() {
    return (
        <Table/>
    );
}

function Table({}) {
    const [inputValue, setInputValue] = useState('');
    const [query, setQuery] = useState('');

    const dataSource = useMemo(() => new OperationLogsDataSource(query), [query]);

    return (
        <>
            <Input.Search
                id="table-search-box"
                onChange={e => setInputValue(e.target.value)}
                value={inputValue}
                enterButton
                onSearch={() => {setQuery(inputValue)}}
                style={{marginBottom: '16px'}}
            />
            {query &&`Search results for: ${query}`}
            <OperationLogsTable dataSource={dataSource}/>
        </>
    );
}
