import {useState} from "react";
import {Form, Input, Radio, Select, Space, Spin} from "antd";
import {EhApi} from "../../../../services";


export function EntityAttributeFilterEditor({schemas, onEditedFilterChanged, debounceRate=500}) {
    const [attribute, setAttribute] = useState(null);
    const [value, setValue] = useState([]);
    const [options, setOptions] = useState([]);
    const [debounceTimer, setDebounceTimer] = useState(null);
    const [filterOperation, setFilterOperation] = useState(filterOperationsMap.equal);
    const [loading, setLoading] = useState(false);
    const [allowedOperationModes, setAllowedOperationModes] = useState([]);
    const [searchValue, setSearchValue] = useState(null);

    const selectAttribute = value => {
        setAttribute(value);
        const schema = schemas.find(x => x.attribute.value === value) ?? {
            options: async () => [],
            ctor: () => ({
                canBeAdded: () => false
            })
        };

        const allowedModes = schema.allowedModes ?? [filterOperationsMap.equal, filterOperationsMap.contains];
        setAllowedOperationModes(allowedModes);
        onEditedFilterChanged(schema.ctor([], [], allowedModes[0]));
        setFilterOperation(allowedModes[0]);
        setSearchValue(null);
        setOptions([]);
        setValue([]);
        setLoading(true);
        schema.options().then(setOptions).finally(_ => {setLoading(false)});
    }

    const handleSearchValue = q => {
        setSearchValue(q);
        const schema = schemas.find(x => x.attribute.value === attribute) ?? {
            options: async () => [],
            isCalculated: true
        };

        setOptions([]);

        clearTimeout(debounceTimer);
        if (schema.isCalculated) {
            schema.options(q).then(setOptions);
        } else {
            setLoading(true);
            setDebounceTimer(
                setTimeout(() => {
                    schema.options(q).then(setOptions).finally(_ => {setLoading(false)});
                }, debounceRate)
            );
        }
    }

    const valueEditors = {
        [filterOperationsMap.equal]: (
            <Select
                notFoundContent={(
                    <Spin spinning={loading}>
                        Option "{searchValue}" not found
                    </Spin>
                )}
                placeholder={"(empty)"}
                loading={loading}
                placement={"topLeft"}
                mode="multiple"
                size={"small"}
                showArrow
                defaultValue={[]}
                style={{width: '100%'}}
                options={options}
                value={value}
                searchValue={searchValue}
                onSearch={handleSearchValue}
                onChange={(value, option) => {
                    const schema = schemas.find(x => x.attribute.value === attribute);
                    onEditedFilterChanged(schema.ctor(value, option, filterOperation));
                    setValue(value);
                }}
            />
        ),
        [filterOperationsMap.contains]: (
            <Input
                size="small"
                value={value[0]}
                onChange={e => {
                    const targetValue = [e.target.value];
                    const targetOptions = targetValue.map(x => ({value: x, label: x}));
                    const schema = schemas.find(x => x.attribute.value === attribute);
                    onEditedFilterChanged(schema.ctor(targetValue, targetOptions, filterOperation));
                    setValue(targetValue);
                }}
            />
        )
    }

    return (
        <>
            <Form.Item label="Attribute">
                <Select
                    size={"small"}
                    showArrow
                    showSearch
                    defaultValue={null}
                    style={{width: '100%'}}
                    options={schemas.map(x => x.attribute)}
                    onSelect={selectAttribute}
                />
            </Form.Item>
            <Form.Item label="Value">
                {valueEditors[filterOperation]}
            </Form.Item>
            <Radio.Group
                value={filterOperation}
                onChange={e => {
                    setFilterOperation(e.target.value);
                    setValue([]);
                }}
                defaultValue={filterOperationsMap.equal}
            >
                <Space direction="vertical">
                    <Radio
                        value={filterOperationsMap.equal}
                        disabled={!allowedOperationModes.includes(filterOperationsMap.equal)}
                    >
                        Full match
                    </Radio>
                    <Radio
                        value={filterOperationsMap.contains}
                        disabled={!allowedOperationModes.includes(filterOperationsMap.contains)}
                    >
                        Partial match
                    </Radio>
                </Space>
            </Radio.Group>
        </>
    );
}


export const filterOperationsMap = {
    equal: "eq",
    contains: "contains"
}


export function BaseOptionsSource(ns, map, limit=20) {
    return q => new Promise((resolve, reject) => {
        EhApi.get(
            ns,
            {params: {q, limit}}
        ).then(response => {
            resolve(response.data.map(map));
        }).catch(reject);
    });
}


export function FilterSchemaBaseEntityAttribute(
    attribute, optionsSource, concreteFilterSource, rest={}
) {
    if (!rest.type) {
        rest.type = 'entity_attributes';
    }

    return {
        attribute: attribute,
        options: q => optionsSource(q),
        ctor: (values, options, operation) => {
            return ({
                canBeAdded: () => true,
                toConcreteFilter: () => concreteFilterSource(options, operation)
            });
        },
        ...rest
    }
}

