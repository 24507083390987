import {useEffect, useState} from "react";
import {EhApi} from "../../services";
import {FullHeightCard} from "../../components/cards";
import {AsyncDataSource, ManualPagedTable} from "../../components/tables";
import {HierarchySettings, NodeInfo, NullSelectedNode, SelectedNodeContent} from "../../components/hierarchy";
import {createLink} from "../boms/Boms";
import {MaterialCreatingModal} from "./MaterialCreatingModal";
import {MaterialNode} from "../../components/hierarchy/response-to-tree-data/response-adapters";
import AutoSizer from "react-virtualized-auto-sizer";
import {ResizableRow, WithPersistedColumns} from "../../layouts";
import {useLocation} from "react-router-dom";
import {MaterialsTree} from "./materials-tree/MaterialsTree";


export function Materials() {
	const [creatingModalVisible, setCreatingModalVisible] = useState(false);
	const [dataSource] = useState(new MaterialsDataSource());
	const [selectedNode, setSelectedNode] = useState(new NullSelectedNode());
	const [selectedMaterial, setSelectedMaterial] = useState({
		root: async () => []
	});
	const location = useLocation();

	const columns = [
		{
			title: 'Item',
			dataIndex: 'title',
			render: (title, item) => (
				<a onClick={_ => onDetailsClick(item.id, item.entity_id)}>
					{title}
				</a>
			)
		},
		{
			title: 'Description',
			dataIndex: 'description',
		},
		{
			title: 'Actions',
			dataIndex: 'id',
			render: (id, item) => (
				<a onClick={_ => onDetailsClick(id, item.entity_id)}>
					Details
				</a>
			)
		}
	];

	const selectMaterial = id => {
		setSelectedMaterial({
			root: async () => EhApi.get(
				`/materials/${id}/preview`
			).then(response => [MaterialNode(response.data, '', setSelectedNode)])
		})
	}

	const onDetailsClick = (id) => {
		selectMaterial(id);
	}

	const handleNewMaterialClick = e => {
		setCreatingModalVisible(true);
	}

	useEffect(() => {
		const found = new URLSearchParams(location.search).get('material')
		if (found) {
			selectMaterial(found);
		}
	}, [location]);

	const MaterialsTable = (
		<FullHeightCard title={"Materials"} extra={<a onClick={handleNewMaterialClick}>Create new Material</a>}>
			<ManualPagedTable id={"materials-table"} asyncDataSource={dataSource} columns={columns} size={"small"}/>
		</FullHeightCard>
	);

	const selectedNodeContent = (
		<SelectedNodeContent selectedNode={selectedNode} open={createLink}>
			{props => (
				<FullHeightCard title={props.nodePath}>
					<NodeInfo {...props}/>
				</FullHeightCard>
			)}
		</SelectedNodeContent>
	);

	const materialsTree = (
		<FullHeightCard extra={<HierarchySettings/>} size={"small"}>
			<MaterialsTree selectedMaterial={selectedMaterial} setSelectedNode={setSelectedNode}/>
		</FullHeightCard>
	);

	const handleCreate = data => {
		selectMaterial(data.id);
	}

	return (
		<>
			<MaterialCreatingModal
				visible={creatingModalVisible}
				updateVisibility={setCreatingModalVisible}
				onCreate={handleCreate}
			/>
			<RowLayout left={MaterialsTable} right={selectedNodeContent} center={materialsTree}/>
		</>
	);
}

export class MaterialsDataSource extends AsyncDataSource {
	constructor(q = "") {
		super();
		this._q = q;
	}

	async values(page, limit) {
		return EhApi.get(
			'/materials',
			{params: {page: page, limit: limit, q: this._q}}
		).then(
			response => response.data.map(el => DataItem(el.id, el.entity_id, el.entity_id, el.description))
		);
	}
}

function DataItem(id, entity_id, title, description) {
	return {key: id, id, entity_id, title, description};
}


function RowLayout({right, left, center}) {
	const defaultColumns = [
		{val: 0.3, min: 0.3, max: 1.0, key: 'table'},
		{val: 0.3, min: 0.2, max: 1.0, key: 'tree'},
		{val: 0.3, min: 0.2, max: 1.0, key: 'selected_node'}
	];

	return (
		<AutoSizer>
			{({width, height}) => (
				<WithPersistedColumns storageKey={"materials"} defaultColumns={defaultColumns}>
					{props => (
						<ResizableRow
							{...props}
							width={width}
							height={height}
							renders={{
								selected_node: right,
								table: left,
								tree: center
							}}
						/>
					)}
				</WithPersistedColumns>
			)}
		</AutoSizer>
	);
}
