import EntityTypes from "./EntityTypes";

export {EntityTypes}

export function empty() {

}


export function lastElementOf(arr) {
    return arr[arr.length - 1];
}


export function withoutDuplicates(origin) {
    return [...new Set(origin)];
}
