import AutoSizer from "react-virtualized-auto-sizer";
import {ResizableRow, WithPersistedColumns} from "./ResizableRow";

export function AutoResizableRows({storageKey, schema={columns: [], renders: {}}}) {
	return (
		<AutoSizer>
			{({width, height}) => (
				<WithPersistedColumns storageKey={storageKey} defaultColumns={schema.columns}>
					{props => (
						<ResizableRow
							{...props}
							width={width}
							height={height}
							renders={schema.renders}
						/>
					)}
				</WithPersistedColumns>
			)}
		</AutoSizer>
	);
}
