import {orDelimiter, FilterFromUrlParam} from "../../../parsers/from-url";


export function EntityAttributeFilterFromUrl(key, ctor) {
    return FilterFromUrlParam(
        name => name.startsWith(key),
        (value, name) => {
            const filterParts = name.match(`^${key}\\[(.*)\\]$`);
            const operation = filterParts[1];
            const values = value.split(orDelimiter);
            return ctor(
                values.map(v => ({value: v, label: v})),
                operation
            );
        }
    )
}
