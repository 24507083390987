import {Col, Row} from "antd";
import {FullHeightCard} from "../../components/cards";
import {Tabs} from 'antd';
import {ImportHierarchyTab} from "./ImportHierarchy";
import {ImportClassLibraryTab} from "./ImportClassLibrary";
import {ExportHierarchyTab} from "./ExportHierarchyTab";
import {OperationLogsTab} from "./OperationLogsTab";
import {GlobalSettingsTab} from "./GlobalSettingsTab";

const {TabPane} = Tabs;

export function Settings() {

    return (
        <Row style={{height: "100%", maxHeight: "100%"}} gutter={[8, 8]}>
            <Col span={24} style={{height: "100%", maxHeight: "100%"}}>
                <FullHeightCard>
                    <Tabs defaultActiveKey="operation_logs" destroyInactiveTabPane={true}>
                        <TabPane tab="Operation Logs" key="operation_logs">
                            <OperationLogsTab/>
                        </TabPane>
                        <TabPane tab="Import Hierarchy" key="import_hierarchy">
                            <ImportHierarchyTab/>
                        </TabPane>
                        <TabPane tab="Import Class Library" key="import_class_library">
                            <ImportClassLibraryTab />
                        </TabPane>
                        <TabPane tab="Export Hierarchy" key="export_hierarchy">
                            <ExportHierarchyTab/>
                        </TabPane>
                        <TabPane tab="Settings" key="project_settings">
                            <GlobalSettingsTab/>
                        </TabPane>
                    </Tabs>
                </FullHeightCard>
            </Col>
        </Row>
    )
}