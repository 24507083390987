import {Breadcrumb, message} from "antd";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {SmartAttributes} from "./SmartAttributes";
import {NodePath} from "../response-to-tree-data/response-adapters";
import {EntityLinksSource} from "../../dropdowns";


export function SelectedNodeContent({selectedNode, open, children, ...rest}) {
	const [namedPath, setNamedPath] = useState({value: new NodePath(), open: _ => {}});
	const [parents, setParents] = useState([]);
	const [attributes, setAttributes] = useState([]);
	const [pmPlans, setPmPlans] = useState({hidden: true});
	const [logs, setLogs] = useState({hidden: true});
	const [links, setLinks] = useState(new EntityLinksSource());

	const history = useHistory()

	useEffect(() => {
		const emitErrorMessage = () => message.error('Error');

		selectedNode.namedPath().then(setNamedPath).catch(emitErrorMessage);
		selectedNode.parents().then(setParents).catch(emitErrorMessage);
		selectedNode.attributes().then(setAttributes).catch(emitErrorMessage);
		selectedNode.links().then(setLinks).catch(emitErrorMessage);
		setPmPlans(selectedNode.pmPlans());
		setLogs(selectedNode.logs());
	}, [selectedNode]);

	return children({
		logs,
		nodePath: <NamedPath attributes={attributes} namedPath={namedPath}/>,
		links: links,
		parents: parents,
		pmPlans: pmPlans,
		attributes: attributes,
		open: (type, title) => open(type, title, history),
		onEdit: values => {
			selectedNode.links().then(setLinks);
			return selectedNode.edit(values).then(setAttributes).then(_ => {
				setLogs(selectedNode.logs());
			});
		},
		...rest
	});
}


function NamedPath({attributes, namedPath}) {
	const smartAttributes = new SmartAttributes(attributes);
	const actualizedPath = smartAttributes.actualizeNodePath(namedPath.value);
	return (
		<Breadcrumb>
			{
				actualizedPath.parts().map(part => (
					<Breadcrumb.Item key={part.asString()}>
						<a onClick={_ => namedPath.open(part)}>
							{part.title()}
						</a>
					</Breadcrumb.Item>
				))
			}
		</Breadcrumb>
	);
}
