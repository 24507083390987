import {Dropdown, Menu} from "antd";
import {LinkOutlinedButton} from "../../buttons/SimpleButtons";


export function EntityLinksDropdown({source, triggerStyle={}}) {
    const items = source.filter([
        {
            label: 'Inventory',
            icon: <LinkIcon title={'Inventory'} src={process.env.PUBLIC_URL + '/images/kbr/ih.png'}/>,
            key: 'ih'
        },
        {
            label: 'Data Mapper',
            icon: <LinkIcon title={'Data Mapper'} src={process.env.PUBLIC_URL + '/images/kbr/dm.png'}/>,
            key: 'dm'
        },
        {
            label: 'Knowledge',
            icon: <LinkIcon title={'Knowledge'} src={process.env.PUBLIC_URL + '/images/kbr/kh.png'}/>,
            key: 'kh'
        },
        // {
        //     label: 'KBR SIMS Workbench',
        //     icon: <LinkIcon title={'KBR SIMS Workbench'} src={process.env.PUBLIC_URL + '/images/kbr/analytics.png'}/>,
        //     key: 'wb'
        // },
        {
            label: 'Equipment',
            icon: <LinkIcon title={'Equipment'} src={process.env.PUBLIC_URL + '/images/kbr/eh.svg'}/>,
            key: 'eh'
        },
    ]);

    const overlay = (
        <Menu
            onClick={({key}) => {
                setTimeout(() => {
                    window.open(source.urlByType(key), '_blank').focus();
                });
            }}
            items={items}
        />
    );

    return items.length > 0 && (
        <Dropdown overlay={overlay}>
            <LinkOutlinedButton style={triggerStyle}/>
        </Dropdown>
    );
}


function LinkIcon({title, src}) {
    return (
        <img alt={title} width={24} height={24} src={src} style={{marginRight: '4px'}}/>
    );
}

