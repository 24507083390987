import {AdaptedSelectIdDataSource} from "../../tables";
import {SelectIdContextModal} from "./SelectIdContextModal";
import {MaterialsDataSource} from "../../../pages/materials/Materials";
import {useCallback} from "react";


export function AssignMaterialModal() {
	const dataSourceCtor = useCallback((q) => {
		return new AdaptedSelectIdDataSource(
			new MaterialsDataSource(q)
		);
	}, []);

	return (
		<SelectIdContextModal
			dataSourceCtor={dataSourceCtor}
			title={subject => `Assign Material to: ${subject.title}`}
			expectedType={'assign_material'}
		/>
	);
}
