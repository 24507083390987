import {ExtractedAttribute} from "./ExtractedAttribute";
import React from "react";
import {ConstructionTypeSelect} from "../../../forms/data-items";
import {FilterableFormItem} from "../../../forms";


export class ExtractedConstructionType extends ExtractedAttribute {
    extract(model) {
        return [{
            path: 'construction_type_name',
            label: 'Construction Type',
            value: model.construction_type_name,
            type: 'text',
            toFormItem: () => (
                <FilterableFormItem
                    name={["construction_type_name"]}
                    label={"Construction Type"}
                    key={"construction_type_name"}
                >
                    <ConstructionTypeSelect/>
                </FilterableFormItem>
            ),
            actualizeTreeItemFields: i => {
                i.constructionTypeName = model.construction_type_name
            },
        }];
    }
}
