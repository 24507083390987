import {useState} from "react";
import {EhApi} from "../../../services";
import {AdaptedSelectIdDataSource, AsyncDataSource, SelectIdTable} from "../../../components/tables";


export function ConstructionTypesTable({onDetailsClick}) {
    const [dataSource] = useState(new AdaptedSelectIdDataSource(new ConstructionTypeDataSource()));

    return (
        <SelectIdTable
            id={"construction-types-table"}
            onSelect={onDetailsClick}
            asyncDataSource={dataSource}
            extractId={r => r.id}
        />
    );
}


export class ConstructionTypeDataSource extends AsyncDataSource {
    constructor(q = "") {
        super();
        this._q = q;
    }

    async values(page, limit) {
        return EhApi.get(
            '/construction-types',
            {params: {page: page, limit: limit, q: this._q}}
        ).then(
            response => {
                return response.data.map(el => DataItem(el.id, el.entity_id, el.description));
            }
        );
    }
}


function DataItem(id, entity_id, description) {
    return {key: id, id, entity_id, description}
}
