import FileDownload from "js-file-download"
import {useState} from "react";
import {EhApi} from "../../services";
import {Button, message, Spin} from "antd";
import {DownloadOutlined} from "@ant-design/icons";

export function ExportHierarchyTab({}) {
    const [loading, setLoading] = useState(false);

    const handleDownloadClick = () => {
        setLoading(true);
        EhApi.get('/hierarchies/export', {'responseType': 'blob'})
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, filename);
            })
            .catch(() => {
                message.error('Failed to export Hierarchy');
            })
            .finally(() => setLoading(false));
    }

    return (
        <Spin spinning={loading}>
            <Button onClick={handleDownloadClick}><DownloadOutlined/> Download Excel file</Button>
        </Spin>
    );
}