import {Tag} from "antd";
import {EntityTypes} from "../utils";


export function TypeTag({_type}) {
    return (
        <Tag
            style={{color: 'black'}}
            color={EntityTypes.nodeColorByType(_type)}
        >
            {EntityTypes.prettyNameByType(_type)}
        </Tag>
    )
}
